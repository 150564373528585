import React from 'react';

import './package.scss';

type PackageComponentWrapperProps = {
  additionalClass?: string;
  childrenClass?: string;
  children: React.ReactNode;
  maxWidth?: boolean;
  minWidth?: boolean;
};

export const PackageComponentWrapper = ({
  additionalClass,
  childrenClass,
  children,
  maxWidth = true,
  minWidth = true
}: PackageComponentWrapperProps): JSX.Element | null => {
  const styles: {
    backgroundColor?: string;
    fontSize: string;
    minWidth?: string;
    width: string;
  } = {
    fontSize: '0',
    minWidth: minWidth ? '375px' : 'unset',
    width: '100%'
  };

  return (
    <section style={{ ...styles }} className={additionalClass || ''}>
      <div
        style={maxWidth ? { maxWidth: '1440px', margin: '0 auto' } : undefined}
        className={childrenClass || ''}>
        {children}
      </div>
    </section>
  );
};
