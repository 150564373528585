import { LoadingSkeleton } from '../../../Loader/Skeleton';

export const Loader = () => {
  return (
    <div
      className="brand-tile-container padding-bottom-10"
      data-test="brand-tile">
      <div className="tile flex-column align-items-center justify-content-center">
        <LoadingSkeleton
          additionalClass="margin-bottom-8 br-16"
          width="125px"
          height="125px"
        />
        <h5
          className="body-l font-bold text-primary margin-0"
          data-test="header">
          <LoadingSkeleton width="100px" />
        </h5>
      </div>
    </div>
  );
};
