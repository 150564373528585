import Link from 'next/link';

import { DatalayerAnalytics, slugify } from 'services';
import { SpecialTileProps } from './index';
import { ecommerceSanitizeQuery } from '../../../../utils/url';

import SpecialTag from '../../../../assets/svgs/specials/tag-circle-red.svg';
import { useDispensaryPathContext } from '../../../../hooks/dispensaryPathContextProvider';

export const SpecialTile = ({
  containingCarouselName,
  containingPageType,
  id,
  index,
  onClick,
  specialLocation,
  title
}: SpecialTileProps) => {
  const { currentPathDispensary } = useDispensaryPathContext();
  const url = slugify(
    `${currentPathDispensary.shopLink}/specials/${id}`
  ).replace(/\/+/g, '/');

  if (!url) return null;
  return (
    <Link
      tabIndex={0}
      onClick={() => {
        onClick && onClick();
        DatalayerAnalytics.pushBasicEvent(
          specialLocation === 'drawer'
            ? 'specials_drawer_click'
            : 'specials_click',
          {
            click_location: 'specials_tile',
            page_type: containingPageType,
            item_name: title,
            index,
            item_list_id: undefined,
            item_list_name: containingCarouselName
          },
          currentPathDispensary
        );
      }}
      href={ecommerceSanitizeQuery(url)}
      className="special-tile-container bg-white pointer d-flex align-items-center">
      <div className="special-icon">
        <SpecialTag />
      </div>
      <p className="special-title body-xl margin-0 text-primary">{title}</p>
    </Link>
  );
};
