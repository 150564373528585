'use client';

import { useEffect, useState } from 'react';

import { StorefrontCarousel as Default } from './default';
import { Loader } from './loader';
import { ProductTileProps } from '../ProductTile';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import {
  productVariantsMap,
  productsAnalyticsMap
} from '../../utils/storefrontCarouselsMap';
import { DatalayerAnalytics } from 'services';
import { moodiDayScript } from '../../utils/moodiDayScript';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';

import './storefront-carousel.scss';

export type HomePageStorefrontCarouselProps = {
  banners: Omit<ProductTileProps, 'analyticsContext'>[];
  title: string;
  type: 'products';
};

export const PopularProductsCarousel = (): JSX.Element | null => {
  const { addItemToCart, userMenuType, websiteUrl } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();

  const [loading, setLoading] = useState<boolean>(!!currentPathDispensary);
  const [productLists, setProductLists] =
    useState<HomePageStorefrontCarouselProps | null>(null);

  useEffect(() => {
    if (currentPathDispensary) {
      setLoading(true);
      fetch(
        `${websiteUrl}/api/product-listing/${currentPathDispensary.uid}?menuType=${userMenuType}`,
        { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
      )
        .then((res) => res.json())
        .then(({ data }) => {
          if (data && data[0].products) {
            setProductLists({
              ...data[0],
              banners: productsAnalyticsMap(
                addItemToCart,
                productVariantsMap(data[0].products, userMenuType),
                undefined
              )
            });
          }
        })
        .catch((error) => {
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'packages/ui/components/StorefrontCarousel/homePage.tsx',
            description:
              (error as string) ||
              'Error fetching product-listing based on selected dispensary.',
            consolidateDispensary: currentPathDispensary || undefined
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [currentPathDispensary]);

  useEffect(() => {
    if (!loading) {
      moodiDayScript();
    }
  }, [loading]);

  if (loading) {
    return (
      <div className="margin-bottom-32">
        <Loader type={'products'} />;
      </div>
    );
  }
  if (productLists && !loading) {
    return (
      <div className="margin-bottom-32">
        <Default
          title={productLists.title}
          type="products"
          banners={productLists.banners}
          isLoading={false}
        />
      </div>
    );
  }
  return null;
};
