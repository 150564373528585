import { LoadingSkeleton } from '../../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  return (
    <div className="new-product-sort-filter psf-loader">
      <div>
        <div className="d-flex justify-content-between align-items-center filter-wrapper">
          <div className="left-side">
            <div className="d-flex gap-24">
              <div className="reverse-column-flex">
                <LoadingSkeleton height="44px" width="130px" />
              </div>
            </div>
          </div>
          <div className="right-side d-flex gap-24">
            <div className="sort-by">
              <LoadingSkeleton height="56px" width="208px" />
            </div>
            <div className="d-flex gap-12 view-button-group">
              <LoadingSkeleton height="56px" width="56px" />
              <LoadingSkeleton height="56px" width="56px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
