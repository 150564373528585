import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import { FILTER_KEYS, SEARCH_QUERY_KEYS } from '../../../../utils/constants';
import { EcomProductPageType } from '../../../../models/productPage';
import { SortBy } from '../../../../models/types';
import { FilterAccordion } from './FilterAccordion';
import { CTAButton } from '../../../CTAButton';

import './filter-modal.scss';

import Close from '../../../../assets/svgs/close/modal-close.svg';

export type FilterMenuItem = {
  id?: string;
  label: string;
  isSelected: boolean;
  key: string;
  values?: {
    min: number;
    max: number;
    current: [number, number];
  };
  unit?: '%' | 'mg';
};

export type FilterKeys = (typeof FILTER_KEYS)[number];

export type FilterMenus = {
  [key in FilterKeys]: FilterMenuItem[];
};

type FilterModalProps = {
  filters: FilterMenus;
  isLoading: false;
  handleApplyFilter: () => void;
  onClear: () => void;
  onClose: () => void;
  pageType: EcomProductPageType['type'];
  searchParams: Params;
  setSelectedFilters: Dispatch<SetStateAction<FilterMenus>>;
  shopLink: string;
  sortBy: SortBy;
};

export const FilterModal = ({
  filters,
  handleApplyFilter,
  onClear,
  onClose,
  pageType,
  searchParams,
  setSelectedFilters,
  shopLink,
  sortBy
}: FilterModalProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (searchParams) {
      let counter = 0;
      SEARCH_QUERY_KEYS.map((qk) => {
        const key = searchParams[qk];
        if (key && typeof key === 'string') {
          const value = qk === 'p' ? 1 : key.split(',').length;
          counter += value;
        }
      });
      setCount(counter);
    }
  }, [searchParams]);

  const handleSelectFilter = (header: FilterKeys, filter: FilterMenuItem) => {
    const currSelectedFilterMenu: FilterMenus = {
      ...filters
    };
    const itemInd: number | undefined = currSelectedFilterMenu[
      header
    ]?.findIndex((item) => item.key === filter.key);
    if (itemInd >= 0 && currSelectedFilterMenu[header][itemInd]) {
      let currCount = count;
      if (
        header === 'Potency' &&
        currSelectedFilterMenu[header][itemInd].values
      ) {
        currSelectedFilterMenu[header][itemInd] = filter;
        setCount(++currCount);
      } else {
        currSelectedFilterMenu[header][itemInd].isSelected =
          !currSelectedFilterMenu[header][itemInd].isSelected;
        setSelectedFilters(currSelectedFilterMenu);
        if (currSelectedFilterMenu[header][itemInd].isSelected) {
          setCount(++currCount);
        } else {
          setCount(--currCount);
        }
      }
    }
  };

  return (
    <>
      <div className="filter-modal" data-test="filter-accordion-wrapper">
        {Object.keys(filters).map((menu, i) => {
          const key = menu as keyof typeof filters;
          if (key && filters[key]) {
            return (
              <FilterAccordion
                key={key}
                props={{
                  defaultOpen: i === 0,
                  isLoading: false,
                  filters,
                  onClose,
                  onSelect: handleSelectFilter,
                  header: key,
                  pageType,
                  shopLink,
                  sortBy
                }}
              />
            );
          }
        })}
      </div>
      <div className="filter-modal-results-button d-flex flex-column padding-24">
        <CTAButton
          text="Apply"
          callBackFunc={handleApplyFilter}
          variant="primary"
          fullWidth={true}
        />
        <a
          className="text-center d-flex gap-8 padding-left-16 padding-right-16 padding-top-8 padding-bottom-8 align-items-center justify-content-center margin-top-10 pointer"
          aria-disabled={count <= 0}
          data-disabled={count <= 0}
          role="button"
          tabIndex={0}
          onClick={() => {
            setCount(0);
            onClear();
          }}>
          <Close />
          <span>Clear All</span>
        </a>
      </div>
    </>
  );
};
