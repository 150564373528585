'use client';

import { useState } from 'react';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';
import { CTAButton } from '../CTAButton';

import GreenArrow from '../../assets/svgs/arrows/icon-green-arrow.svg';

import './decision-tree.scss';

export type DecisionTreeProps = {
  anchor: string;
  questions: DecisionTreeDataType;
  startingHeader: string;
};

export type DecisionTreeDataType = DecisionTreeCircular<DecisionTreeDataType>;

interface DecisionTreeCircular<T> {
  question: string;
  answers: AnswerType<T>[];
}

export type AnswerType<T> = {
  text: string;
  reference: T;
};

export const DecisionTree = ({
  anchor = 'decision-tree',
  questions,
  startingHeader
}: DecisionTreeProps): JSX.Element | null => {
  const [state, setState] = useState('start');
  const [current, setCurrent] = useState(questions);
  const [previous, setPrevious] = useState<DecisionTreeDataType[]>([]);

  return (
    <PackageComponentWrapper>
      <div className={`decision-tree`} data-test="descision-tree" id={anchor}>
        {state === 'start' && (
          <div
            data-test="starting-header"
            onClick={() => setState('started')}
            className="header-block small-border-radius">
            <div className="starting-header text-center">
              <Markdown content={startingHeader} />
            </div>
          </div>
        )}
        {state === 'started' && (
          <div
            data-test="question-block"
            className="question-block small-border-radius">
            {current.answers.length ? (
              <div
                className="question-back padding-left-16"
                onClick={() => {
                  if (previous.length) {
                    const newArr = [...previous];
                    const newPrevious = newArr.shift();
                    if (newPrevious) {
                      setPrevious(newArr);
                      setCurrent(newPrevious);
                    }
                  }
                }}>
                {previous.length ? (
                  <>
                    <GreenArrow />
                    <p>Back</p>
                  </>
                ) : null}
              </div>
            ) : (
              <div
                className="question-start-over"
                onClick={() => {
                  setState('start');
                  setCurrent(questions);
                  setPrevious([]);
                }}>
                <p>Start Over</p>
              </div>
            )}
            <div className="text-center">
              <Markdown data-test="question-text" content={current.question} />
            </div>
            <div className="answer-block">
              {current.answers.length
                ? current.answers.map((a: AnswerType<DecisionTreeDataType>) => (
                    <CTAButton
                      data-test="answer-button"
                      key={a.text}
                      variant="primary"
                      callBackFunc={() => {
                        setPrevious([current, ...previous]);
                        setCurrent(a.reference);
                      }}
                      text={a.text}
                    />
                  ))
                : null}
            </div>
          </div>
        )}
      </div>
    </PackageComponentWrapper>
  );
};
