import {
  DayTimes,
  Consolidate_Dispensary,
  OverviewPageDispensary,
  capitalize
} from 'services';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { useEffect, useState } from 'react';
import { CTAButton } from '../../CTAButton';

export type StoreOpeningHoursProps = {
  times: OverviewPageDispensary['openTimes'];
  timeSets: Consolidate_Dispensary['timesets'];
};

const StoreOpeningHours = ({
  times,
  timeSets
}: StoreOpeningHoursProps): JSX.Element | null => {
  const { width } = useSiteWideContext();
  const [selectedTimeSet, setSelectedTimeSet] = useState<
    StoreOpeningHoursProps['timeSets'][number] | null
  >(null);

  useEffect(() => {
    if (timeSets?.length && timeSets[0]) {
      setSelectedTimeSet(timeSets[0]);
    }
  }, [timeSets]);

  if (!times || !Object.keys(times).length) return null;

  const renderTimes = (item: string, value: DayTimes[]) => (
    <div
      key={item}
      className="hours d-flex flex-row justify-content-between"
      data-test="info-section">
      <p className="margin-0" data-test="opening-hours-range">
        {capitalize(item)}
      </p>
      <p className="margin-0" data-test="opening-hours-times">
        {value
          .map((v, i) => {
            const multipleHours = value.length > 1 && i === 0;
            const lineBreak = width && width <= 915 && multipleHours;
            return v.startTime && v.endTime ? (
              <span key={`${item}-${i}`}>
                {v.startTime} - {v.endTime}
                {multipleHours ? ', ' : ''}
                {lineBreak ? <br /> : null}
              </span>
            ) : (
              'CLOSED'
            );
          })
          .filter(Boolean)}
      </p>
    </div>
  );

  return (
    <section
      className="store-hours d-flex flex-column bg-secondary-light padding-16 "
      data-test="opening-hours">
      {timeSets?.length && selectedTimeSet ? (
        <div className="d-flex gap-8 margin-bottom-8">
          {timeSets.map((timeSet) => {
            return (
              <CTAButton
                key={timeSet.title}
                callBackFunc={() => setSelectedTimeSet(timeSet)}
                size="md"
                text={timeSet.title}
                variant={
                  selectedTimeSet.title === timeSet.title
                    ? 'primary'
                    : 'secondary'
                }
              />
            );
          })}
        </div>
      ) : null}
      <h6
        className="title-l text-primary margin-bottom-16"
        data-test="store-info-header">
        Store Hours
      </h6>
      {selectedTimeSet || times ? (
        <div
          className="hours-container d-flex flex-column"
          data-test="times-section">
          {selectedTimeSet
            ? Object.keys(selectedTimeSet.openTimes).map((item) => {
                const value =
                  selectedTimeSet.openTimes[
                    item as keyof typeof selectedTimeSet.openTimes
                  ];
                // Note: Returning null to appease typescript
                if (!value || !value.length) return null;
                return renderTimes(item, value);
              })
            : Object.keys(times).map((item) => {
                const value =
                  times[item as keyof OverviewPageDispensary['openTimes']];
                // Note: Returning null to appease typescript
                if (!value || !value.length) return null;
                return renderTimes(item, value);
              })}
        </div>
      ) : null}
    </section>
  );
};

export default StoreOpeningHours;
