import classNames from 'classnames';
import Link from 'next/link';

import { DatalayerAnalytics, alphanumericSlugify, slugify } from 'services';
import { FilterKeys, FilterMenuItem } from '../../index';
import { ecommerceSanitizeQuery } from '../../../../../../utils/url';

export const FilterRedirectItem = ({
  shopLink,
  header,
  item,
  onClose,
  pageType
}: {
  shopLink: string;
  header: FilterKeys;
  item: FilterMenuItem;
  onClose: () => void;
  pageType: string;
}) => {
  const link =
    pageType === 'AllBrandsPage' ? alphanumericSlugify(item.label) : item.id;
  return (
    <div className="filter-item">
      <Link
        tabIndex={0}
        href={ecommerceSanitizeQuery(`${shopLink}/${slugify(header)}/${link}`)}
        className={classNames('list-item body-m', {
          selected: item.isSelected
        })}
        onClick={() => {
          try {
            DatalayerAnalytics.pushFilterInteraction({
              action: item.isSelected ? 'remove' : 'add',
              filter_type: 'redirect'
            });
            DatalayerAnalytics.pushBasicEvent('category_filter', {
              filter_type: header,
              filter_value: item.label
            });
          } catch (err) {
            console.error(err);
          }
          onClose();
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            try {
              DatalayerAnalytics.pushBasicEvent('category_filter', {
                filter_type: header,
                filter_value: item.label
              });
            } catch (err) {
              console.error(err);
            }
            onClose();
          }
        }}>
        {item.label}
      </Link>
    </div>
  );
};
