import { Sidebar } from 'primereact/sidebar';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DatalayerAnalytics } from 'services';
import { ProductSortFilterProps } from '.';
import {
  FilterKeys,
  FilterMenuItem,
  FilterMenus,
  FilterModal
} from './FilterModal';
import { SortBySelect } from './Sort';
import { FILTER_KEYS } from '../../../utils/constants';
import { SubcategoryTile } from '../../StorefrontCarousel/components/SubcategoryTile/default';

import GridIcon from '../../../assets/svgs/filter-sort/icon-view-grid.svg';
import ListIcon from '../../../assets/svgs/filter-sort/icon-view-list.svg';
import FilterIcon from '../../../assets/svgs/filter-sort/filter.svg';
import CloseIcon from '../../../assets/svgs/close/modal-close.svg';

export const ProductSortFilter = ({
  filters,
  onApplyFilter,
  onSelectFilter,
  pageType,
  productView,
  searchParams,
  setProductView,
  shopLink,
  sortBy
}: ProductSortFilterProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedFilterChips, setSelectedFiltersChips] = useState<
    (FilterMenuItem & { header: FilterKeys })[]
  >([]);
  const [selectedFilterMenu, setSelectedFilterMenus] = useState<FilterMenus>({
    ...filters
  });

  useEffect(() => {
    if (filters) {
      const selectedFiltersArr: (FilterMenuItem & { header: FilterKeys })[] =
        [];
      FILTER_KEYS.map((fKey) => {
        filters[fKey].map((filter) => {
          if (fKey === 'Potency' && filter.values) {
            if (
              filter.values.current[0] !== filter.values.min ||
              filter.values.current[1] !== filter.values.max
            ) {
              selectedFiltersArr.push({
                ...filter,
                header: fKey
              });
            }
          } else if (filter.isSelected) {
            selectedFiltersArr.push({
              ...filter,
              header: fKey
            });
          }
        });
      });
      setSelectedFiltersChips(selectedFiltersArr);
    }
  }, [filters]);

  const closeDrawer = () => setOpen(false);

  const handleApplyFilters = () => {
    onApplyFilter(selectedFilterMenu);
    closeDrawer();
  };

  return (
    <>
      <div className="new-product-sort-filter">
        {pageType === 'SubCategoryPage' && filters.Subcategories.length > 0 ? (
          <div className="subcategory-filter-bar-mobile">
            <Swiper
              allowTouchMove={true}
              focusableElements="input, select, option, textarea, video, label"
              spaceBetween={12}
              slidesPerView="auto">
              {filters.Subcategories.map((sub) => (
                <SwiperSlide key={sub.key}>
                  <SubcategoryTile
                    props={{
                      isLoading: false,
                      filter: sub,
                      onSelectFilter
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : null}
        <div>
          <div className="d-flex justify-content-between align-items-center filter-wrapper">
            <div className="left-side">
              <div className="d-flex gap-24">
                <div className="reverse-column-flex">
                  <button
                    data-test="filter-button"
                    className="filter-button body-m font-bold text-primary sm-border-radius bg-white d-flex align-items-center gap-8 justify-content-center pointer"
                    onClick={() => setOpen(!open)}>
                    <FilterIcon />
                    {selectedFilterChips.length > 0 ? (
                      <span className="selected-filter-count">
                        {selectedFilterChips.length}
                      </span>
                    ) : null}
                    <span className="filter-label">
                      {selectedFilterChips.length === 1 ? 'Filter' : 'Filters'}
                    </span>
                  </button>
                </div>
                {pageType === 'SubCategoryPage' &&
                filters.Subcategories.length > 0 ? (
                  <div className="subcategory-filter-bar-dt">
                    <Swiper
                      allowTouchMove={true}
                      focusableElements="input, select, option, textarea, video, label"
                      spaceBetween={12}
                      slidesPerView="auto">
                      {filters.Subcategories.map((sub) => (
                        <SwiperSlide key={sub.key}>
                          <SubcategoryTile
                            props={{
                              isLoading: false,
                              filter: sub,
                              onSelectFilter
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="right-side d-flex gap-24">
              <div className="sort-by">
                <SortBySelect
                  filters={filters}
                  onSelectFilter={onSelectFilter}
                  sortBy={sortBy}
                />
              </div>
              <div className="d-flex gap-12 view-button-group">
                <button
                  className={classNames('view-button pointer', {
                    selected: productView === 'list'
                  })}
                  onClick={() => {
                    window.localStorage.setItem('productView pointer', 'list');
                    setProductView('list');
                  }}
                  aria-label="product list view">
                  <ListIcon />
                </button>
                <button
                  className={classNames('view-button pointer', {
                    selected: productView === 'grid'
                  })}
                  onClick={() => {
                    window.localStorage.setItem('productView', 'grid');
                    setProductView('grid');
                  }}
                  aria-label="product grid view">
                  <GridIcon />
                </button>
              </div>
            </div>
          </div>
          {selectedFilterChips.length > 0 ? (
            <div className="filter-chip-bar d-flex gap-10 margin-top-10 flex-wrap">
              {selectedFilterChips.map((filter) => {
                return (
                  <button
                    onClick={() => {
                      try {
                        DatalayerAnalytics.pushFilterInteraction({
                          action: 'remove',
                          filter_type: 'chip'
                        });
                      } catch (err) {
                        console.error(err);
                      }

                      if (filter.header === 'Potency' && filter.values) {
                        const menuItem: FilterMenuItem = {
                          ...filter,
                          isSelected: true,
                          values: {
                            ...filter.values,
                            current: [filter.values.min, filter.values.max]
                          }
                        };
                        onSelectFilter(filter.header, menuItem);
                      } else {
                        onSelectFilter(filter.header, filter);
                      }
                    }}
                    key={filter.label}
                    className="filter-chip d-flex gap-8 align-items-center text-primary pointer">
                    <span className="body-sm font-bold">
                      {filter.header === 'Potency' && filter.values
                        ? `${filter.label}: ${filter.values.current[0]}${filter.unit} - ${filter.values.current[1]}${filter.unit}`
                        : filter.label}
                    </span>
                    <CloseIcon />
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <Sidebar
        data-test="sidebar"
        header={
          <div className="d-flex header align-items-center justify-content-between">
            <h2 className="body-l font-bold text-black margin-0">Filters</h2>
            <button
              aria-label="Close filter drawer"
              onClick={() => {
                setSelectedFilterMenus(filters);
                closeDrawer();
              }}
              className="bg-white filter-close-icon pointer">
              <CloseIcon />
            </button>
          </div>
        }
        closeOnEscape
        showCloseIcon={false}
        visible={open}
        position="right"
        className="disable-scroll filter-sidebar"
        onHide={closeDrawer}>
        <FilterModal
          filters={selectedFilterMenu}
          isLoading={false}
          handleApplyFilter={handleApplyFilters}
          onClear={() => {
            setSelectedFilterMenus(filters);
          }}
          onClose={closeDrawer}
          pageType={pageType}
          searchParams={searchParams}
          setSelectedFilters={setSelectedFilterMenus}
          shopLink={shopLink}
          sortBy={sortBy}
        />
      </Sidebar>
    </>
  );
};
