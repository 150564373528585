import { SpecialsBanner as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './specials-banner.scss';

type SpecialsBanner = {
  description: string;
  id: number | string;
  url: string;
};

export type SpecialsBannerProps = {
  banners: SpecialsBanner[];
  isMobile: boolean;
};

export const SpecialsBanner = (
  props: SpecialsBannerProps
): JSX.Element | null => {
  if (!props.banners.length) return null;

  return (
    <ErrorBoundary component="specials-banner">
      <Default {...props} />
    </ErrorBoundary>
  );
};
