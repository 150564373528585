import { Fragment } from 'react';

import { capitalize } from 'services';
import { OrderSummaryPricing, OrderSummaryProps } from '.';
import { formatPrice } from '../../utils/ecommerce';

export const OrderSummary = ({
  pricing,
  type
}: OrderSummaryProps): JSX.Element | null => {
  const summary: OrderSummaryPricing[] = ['subtotal', 'taxes', 'fees', 'total'];
  if (pricing?.discounts && pricing.discounts > 0) {
    summary.splice(1, 0, 'discounts');
  }

  return (
    <>
      {summary.map((ps) => {
        const match = pricing?.[ps] || 0;
        const price = type === 'order' ? match.toFixed(2) : formatPrice(match);
        const isDiscount = ps === 'discounts';
        const bold = ps === 'total' ? 'font-bold' : '';

        if (
          (ps === 'fees' && pricing?.fees === 0) ||
          (ps === 'fees' && !pricing)
        )
          return null;
        return (
          <Fragment key={ps}>
            {bold ? <div className="hairline" /> : null}
            <div key={ps}>
              <div className="d-flex justify-content-between text-primary text-primary">
                <span className={`body-l ${bold}`}>
                  {ps === 'total' ? 'Order Total' : capitalize(ps)}
                </span>
                {isDiscount ? (
                  <span className={`body-l ${bold} text-success-primary`}>
                    -${price}
                  </span>
                ) : (
                  <span className={`body-l font-bold`}>${price}</span>
                )}
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
