import { useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { Markdown } from '../Markdown';
import { SpecialsBannerProps } from './index';

import SpecialsTag from '../../assets/svgs/shared/specials-tag.svg';
import ChevronDown from '../../assets/svgs/arrows/accordion-arrow-lg.svg';

export const SpecialsBanner = ({
  banners,
  isMobile
}: SpecialsBannerProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  if (!banners.length) return null;
  const firstBanner = banners[0];
  if (!firstBanner) return null;

  return (
    <div className="specials-banner">
      <div
        className={classNames('ib-container d-flex align-items-center', {
          'justify-content-center': banners.length === 1,
          'border-radius': !open
        })}>
        <div className="ib-contents d-flex align-items-center">
          {isMobile ? null : (
            <span className="margin-right-10 tag-icon">
              <SpecialsTag />
            </span>
          )}
          <span className="margin-right-10 body-xl font-bold">
            Special Offer:
          </span>
          {banners.length === 1 ? (
            <Link className="underline" href={firstBanner.url}>
              <Markdown
                additionalClass="ib-content"
                content={firstBanner.description}
              />
            </Link>
          ) : (
            <span className="body-xl font-regular">
              {banners.length} offers available
            </span>
          )}
        </div>
        {banners.length > 1 ? (
          <button
            className={classNames('arrow', {
              up: open
            })}
            onClick={() => setOpen(!open)}>
            <ChevronDown />
          </button>
        ) : null}
      </div>
      {banners.length > 1 ? (
        <div
          className={classNames('specials-dropdown', {
            active: open
          })}>
          <ul className="content d-flex flex-column gap-10 padding-left-16 padding-right-16 padding-bottom-16">
            {banners.map((banner) => {
              return (
                <li key={banner.id}>
                  <Link
                    href={banner.url}
                    className="text-primary body-m underline">
                    {banner.description}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
