'use client';

import { useEffect, useState } from 'react';

import { CTAButton } from '../CTAButton';
import { HeaderDrawer } from './Drawer';
import { MainHeaderNavigationProps } from '.';
import { useSiteWideContext } from '../..';
import { SublinkDropdown } from './SublinkDropdown';

import CuraleafLogo from '../../assets/svgs/header/full-logo-teal-main-header.svg';
import MobileCuraleafLogo from '../../assets/svgs/header/circle-logo.svg';
import HamburgerIcon from '../../assets/svgs/header/hamburger-white.svg';

import './main-header-navigation.scss';

export type SublinkState = {
  id?: number;
  show: boolean;
};

export const MainHeaderNavigation = ({
  navLinks
}: MainHeaderNavigationProps): JSX.Element | null => {
  const { selectedDispensary } = useSiteWideContext();
  const [open, setOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window && typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        if (currentScrollY === 0) {
          // Banner is at the top of the page
          setScrolled(false);
        } else if (currentScrollY) {
          // User is scrolling
          setScrolled(currentScrollY >= 20);
        } else {
          setScrolled(true);
        }
      }
    };

    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mobile-main-header-nav d-flex justify-content-between align-items-center container-lr">
        <a
          aria-label="open menu"
          role="button"
          onClick={() => setOpen(!open)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setOpen(!open);
            }
          }}
          tabIndex={0}
          className="hamburger-icon pointer">
          <HamburgerIcon />
        </a>
        <a href="/" className="logo-icon" aria-label="home page">
          <MobileCuraleafLogo />
        </a>
        <CTAButton
          size="md"
          variant="secondary"
          text="Shop now"
          url={selectedDispensary ? selectedDispensary.shopLink : '/locations'}
        />
      </div>
      <HeaderDrawer
        open={open}
        navLinks={navLinks}
        setClose={() => setOpen(false)}
        selectedDispensary={selectedDispensary}
      />
      <div
        data-scrolled={isScrolled}
        className="main-header-nav bg-primary d-flex flex-column">
        <div className="logo-container text-center">
          <a
            className="pointer header-logo text-center"
            href="/"
            aria-label="home">
            <CuraleafLogo />
          </a>
        </div>
        <nav>
          <ul className="nav-links d-flex margin-0 padding-0 justify-content-between align-items-center">
            {navLinks.map((link) => {
              return (
                <li key={link.title}>
                  {link.link ? (
                    <a
                      className="body-xl text-tertiary-primary underline"
                      href={link.link}>
                      {link.title}
                    </a>
                  ) : link.sublinks?.length ? (
                    <SublinkDropdown navLink={link} />
                  ) : null}
                </li>
              );
            })}
            <li>
              <CTAButton
                size="md"
                variant="secondary"
                text="Shop now"
                url={
                  selectedDispensary
                    ? selectedDispensary.shopLink
                    : '/locations'
                }
              />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
