'use client';

import axios from 'axios';
import { usePathname } from 'next/navigation';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { MarketingFormBannerDataType } from 'services';
import { CTAButton } from '../../CTAButton';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { TextInput } from '../../FormBuilder/components/TextInput';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { isEmailValid } from '../../../utils/validators';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { marketingIntakeBannerMap } from '../../../utils/formMap';
import { useDispensaryPathContext } from '../../../hooks/dispensaryPathContextProvider';

import './mailing-opt-in-banner.scss';

export const MailingOptInBanner = (): JSX.Element | null => {
  const { isLoggedIn, isMobile, setUser, user } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const pathname = usePathname();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      optIn: true,
      state: ''
    },
    validate: (data: MarketingFormBannerDataType) => {
      const errors = {} as MarketingFormBannerDataType;

      if (!data.email) {
        errors.email = 'Email is required.';
      } else if (!isEmailValid(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const submit = await axios.post(
          `/api/form/submit`,
          marketingIntakeBannerMap(values)
        );
        if (isLoggedIn && user) {
          setUser({ ...user, optIn: true });
        }
        if (submit.status === 200) setShowConfirmation(true);
        setLoading(false);
        formik.resetForm();
      } catch (err) {
        setLoading(false);
        console.warn(`Error: ${err}`);
      }
    }
  });

  useEffect(() => {
    if (showConfirmation && (!isLoggedIn || (isLoggedIn && user?.optIn))) {
      setShowConfirmation(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (currentPathDispensary) {
      formik.setFieldValue(
        'state',
        currentPathDispensary.location.state.abbreviation
      );
    }
  }, [currentPathDispensary]);

  if (
    (isLoggedIn && user?.optIn && !showConfirmation) ||
    !currentPathDispensary
  ) {
    return null;
  }

  return (
    <PackageComponentWrapper additionalClass="bg-secondary-dark">
      <ErrorBoundary component="marketing-intake-banner">
        {showConfirmation ? (
          <div className="marketing-intake-banner d-flex flex-column align-items-center">
            <h3 className="text-primary title-l text-center margin-0">
              Thank you for opting in to our email marketing updates.
            </h3>
            <p>
              You&apos;re all set to receive exclusive information on our latest
              product drops, deals, and more!
            </p>
          </div>
        ) : (
          <form
            className="marketing-intake-banner d-flex flex-column align-items-center"
            onSubmit={formik.handleSubmit}>
            <div className="header">
              <h3
                className={`text-primary text-center margin-0 ${isMobile ? 'title-l' : 'h3'}`}>
                Sign Up, Stay Up on our latest product drops, deals, and more.
              </h3>
            </div>
            <div className="fields-container d-flex justify-content-center align-items-center">
              <TextInput
                title="Email Address"
                type="email"
                field="email"
                formik={formik}
                customId="marketing-intake-email"
              />
              <CTAButton
                type="submit"
                loading={loading}
                text="SIGN UP"
                variant="primary"
              />
            </div>
            <small className="text-black">
              By signing up for our mailing list, you agree to our &nbsp;
              <span>
                <a
                  className="underline body-sm text-black"
                  href="/terms-of-service">
                  Terms of Use
                </a>
              </span>
              &nbsp; and &nbsp;
              <span>
                <a
                  className="underline body-sm text-black"
                  href="/privacy-policy">
                  Privacy Policy.
                </a>
              </span>
            </small>
          </form>
        )}
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
