import { Dialog } from 'primereact/dialog';
import { Dispatch } from 'react';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CTAButton } from '../CTAButton';

import './switch-menu-dialog.scss';

type Props = {
  callback?: () => void;
  show: boolean;
  setShow: Dispatch<boolean>;
};

export const SwitchMenuDialog = ({ callback, setShow, show }: Props) => {
  const { setUserMenuType, userMenuType } = useSiteWideContext();
  const oppsiteButtonText =
    userMenuType === 'MEDICAL' ? 'Adult-Use' : 'Medical';
  return (
    <Dialog
      className="switch-menu-modal disable-scroll padding-16"
      draggable={false}
      header={
        <h2 className="text-primary h3 margin-0">
          Switch to {oppsiteButtonText} menu?
        </h2>
      }
      footer={
        <div className="d-flex column-gap-5 button-group">
          <CTAButton
            fullWidth={true}
            text="No"
            variant="secondary"
            callBackFunc={() => setShow(false)}
          />
          <CTAButton
            fullWidth={true}
            text="Yes"
            variant="primary"
            callBackFunc={() => {
              setUserMenuType(
                userMenuType === 'MEDICAL' ? 'RECREATIONAL' : 'MEDICAL'
              );
              callback ? callback() : null;
              setShow(false);
            }}
          />
        </div>
      }
      onHide={() => setShow(false)}
      resizable={false}
      visible={show}>
      <PackageComponentWrapper minWidth={false}>
        <p>Are you sure you want to clear your cart and switch menu types?</p>
      </PackageComponentWrapper>
    </Dialog>
  );
};
