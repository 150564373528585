import { PriceSectionProps } from '.';

export const PriceSection = ({
  isMobile,
  type,
  variant
}: PriceSectionProps) => {
  const fixedPrice = (price: number) => {
    if (JSON.stringify(price).includes('.')) {
      return price.toFixed(2);
    }
    return price;
  };

  let additionalClass = '';
  if (type === 'tile') {
    additionalClass += isMobile ? 'title-l' : 'h3';
  } else if (type === 'list-tile' || type === 'cart') {
    additionalClass += 'title-l';
  } else if (type === 'pdp') {
    additionalClass += 'h1';
  }

  if (variant.price) {
    if (variant.isSpecial && variant.specialPrice) {
      if (type === 'tile' || type === 'list-tile') {
        return (
          <div className="d-flex align-items-center">
            <span className={`text-black ${additionalClass}`}>
              ${fixedPrice(variant.specialPrice)}
            </span>
            <span
              className={
                'body-l font-bold old-price strikethrough margin-left-10 text-error-dark'
              }>
              ${fixedPrice(variant.price)}
            </span>
          </div>
        );
      } else if (type === 'pdp') {
        return (
          <div className="d-flex flex-column">
            <span className={`text-black ${additionalClass}`}>
              ${fixedPrice(variant.specialPrice)}
            </span>
            <span className={'title-m font-bold strikethrough text-error-dark'}>
              {isMobile
                ? `$${fixedPrice(variant.price)}`
                : `Was $${fixedPrice(variant.price)}`}
            </span>
            <span className={'title-m font-regular text-success-primary'}>
              Save ${fixedPrice(variant.price - variant.specialPrice)}
            </span>
          </div>
        );
      } else if (type === 'cart' || type === 'pdp') {
        return (
          <div className="d-flex flex-column text-center cart-price-section">
            <span className={`text-black ${additionalClass}`}>
              ${fixedPrice(variant.specialPrice)}
            </span>
            <span
              className={
                'body-l font-bold was-price strikethrough text-error-dark'
              }>
              {isMobile
                ? `$${fixedPrice(variant.price)}`
                : `Was $${fixedPrice(variant.price)}`}
            </span>
            <span className={'body-l save-price text-success-primary'}>
              Save ${fixedPrice(variant.price - variant.specialPrice)}
            </span>
          </div>
        );
      }
    }
    return (
      <div>
        <span className={`text-black ${additionalClass}`}>
          ${fixedPrice(variant.price)}
        </span>
      </div>
    );
  }
  return null;
};
