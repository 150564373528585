'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useInfiniteQuery } from '@tanstack/react-query';
import { throttle } from 'lodash';

import { UnauthorizedPage } from './unauthorized';
import { AccountNavigation } from '../../components/Account/Navigation';
import { BreadCrumbs } from '../../components/Breadcrumbs';
import { CTAButton } from '../../components/CTAButton';
import { Loader } from '../../components/Account/Loader/loader';
import { PackageComponentWrapper } from '../../components/PackageComponentWrapper';
import { OrderHistorySummaryCard } from '../../components/OrderHistory/SummaryCard';
import { OrderHistoryDrawer } from '../../components/OrderHistory/Drawer/index';
import { Curaql, DatalayerAnalytics, Curaql_TransactionRecord } from 'services';

import { useSiteWideContext } from '../../hooks/siteWideContext';

import './account-preferences.scss';

import axios from 'axios';
export const OrderHistoryPage = () => {
  const {
    isLoggedIn,
    loading: isAuthLoading,
    selectedDispensary,
    user
  } = useSiteWideContext();

  const { push } = useRouter();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<Curaql_TransactionRecord | null>(null);
  const [orderList, setOrderList] = useState<Curaql_TransactionRecord[]>([]);
  const [resultOffset, setresultOffset] = useState<number>(0);
  const [isEnd, setIsEnd] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);

  const {
    fetchNextPage,
    data: orderHistory,
    isLoading,
    isFetching
  } = useInfiniteQuery({
    queryKey: ['orderHistory'],
    queryFn: ({ pageParam }: { pageParam: number }) => {
      if (isLoggedIn && user && user.email) {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_ORDER_HISTORY,
            variables: {
              limit: 5,
              offset: pageParam
            }
          },
          withCredentials: true
        })
          .then((res) => {
            return res.data.data.orderHistory;
          })
          .catch((error) => {
            DatalayerAnalytics.pushErrorEvent({
              category: 'api',
              location: 'packages/ui/templates/Account/orders.tsx',
              description:
                (error as string) || 'get order history transactions error',
              consolidateDispensary: selectedDispensary
                ? selectedDispensary
                : undefined
            });
          });
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      return lastPageParam + 5;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      return firstPageParam;
    },
    enabled: !!isLoggedIn,
    refetchOnWindowFocus: false
  });

  const [initialLoad, setInitialLoad] = useState(isAuthLoading || isLoading);

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'account/orders'
    });
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthLoading) {
      if (orderHistory?.pages.length) {
        let allOrders: Curaql_TransactionRecord[] = [];
        if (orderHistory.pages.length) {
          orderHistory.pages.forEach((page) => {
            if (Array.isArray(page)) {
              allOrders = allOrders.concat(page);
            }
          });
        }
        setOrderList(allOrders);
        setInitialLoad(false);

        const updatedOffset = resultOffset + 5;
        setresultOffset(updatedOffset);
        const latestOrderHistory =
          orderHistory.pages[orderHistory.pages.length - 1];
        (latestOrderHistory && latestOrderHistory.length < 5) ||
        !latestOrderHistory
          ? setIsEnd(true)
          : setIsEnd(false);
      } else {
        setOrderList([]);
        setIsEnd(true);
        setInitialLoad(false);
      }
    }
  }, [orderHistory, isLoading]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (
        scrollTop + windowHeight >= documentHeight - footerHeight &&
        !isEnd &&
        resultOffset
      ) {
        fetchNextPage();
      }
    }, 1500);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [resultOffset, footerHeight]);

  const viewOrderFunc = (transaction: Curaql_TransactionRecord) => {
    setSelectedOrder(transaction);
    setDrawerOpen(true);
  };

  useEffect(() => {
    const getFooterHeight = () => {
      const footerContainer = document.querySelector(
        '.footer-container-curaleaf'
      );
      if (footerContainer) {
        const styles = window.getComputedStyle(footerContainer);
        const height = parseInt(
          styles.height || styles.getPropertyValue('height')
        );
        if (height) setFooterHeight(height);
      }
    };
    getFooterHeight();
    window.addEventListener('resize', getFooterHeight);
    return () => window.removeEventListener('resize', getFooterHeight);
  }, []);

  const mapOrders = () => {
    return orderList.map((datum, i) => {
      const transactionItemQuantity = 0;
      return (
        <OrderHistorySummaryCard
          orderSummary={datum}
          transactionItemQuantity={transactionItemQuantity}
          viewOrderFunc={viewOrderFunc}
          key={i}
        />
      );
    });
  };

  if (!isAuthLoading && !isLoading && !isLoggedIn) return <UnauthorizedPage />;

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              breadcrumbName: 'Home',
              urlFragment: '/',
              id: 0
            },
            {
              breadcrumbName: 'Account',
              urlFragment: '/account',
              id: 1
            }
          ],
          currentPage: 'Orders',
          isLoading: false
        }}
      />
      <PackageComponentWrapper additionalClass="account-preferences-wrapper">
        <AccountNavigation currentPage="orders" />
        <OrderHistoryDrawer
          transaction={selectedOrder}
          setVisibility={setDrawerOpen}
          visible={drawerOpen}
        />
        <div className="order-history d-flex flex-column">
          <h1 className="text-center margin-bottom-16 text-primary h3 padding-top-30">
            Orders
          </h1>
          {initialLoad ? (
            <div className="order-history-wrapper">
              <Loader />
            </div>
          ) : orderList.length ? (
            <>
              {mapOrders()}
              <div
                data-loading={initialLoad}
                className="order-end-divider d-flex justify-content-center">
                {isEnd && !initialLoad ? (
                  <>
                    <div className="divider" />
                    <small className="end-text text-center text-primary">
                      End of Results
                    </small>
                    <div className="divider" />
                  </>
                ) : isFetching ? (
                  <Loader />
                ) : (
                  <small className="text-center">Scroll For More</small>
                )}
              </div>
            </>
          ) : (
            <div className="account-preferences text-center d-flex flex-column align-items-center">
              <h2 className="text-primary margin-bottom-16 h3">
                Whoops, looks like you have no order history!
              </h2>
              <p>How about we change that?</p>
              <CTAButton
                text="Start Shopping"
                variant="primary"
                type="button"
                callBackFunc={() => {
                  push(selectedDispensary?.shopLink || '/locations');
                }}
              />
            </div>
          )}
        </div>
      </PackageComponentWrapper>
    </>
  );
};
