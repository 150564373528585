import { Loader } from './loader';
import { ProductDescription as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { SpecialTileProps } from '../StorefrontCarousel/components/SpecialTile';
import { Consolidate_Product } from 'services';

import './product-description-old.scss';

export type EcomProductDescriptionProps = {
  isLoading: false;
  product: Consolidate_Product;
  productSpecials: SpecialTileProps[];
  coaUrl?: string;
};

export type LoadingProps = {
  isLoading: true;
};

export const ProductDescription = ({
  props
}: {
  props: EcomProductDescriptionProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="product-description">
      <Default {...props} />
    </ErrorBoundary>
  );
