import classNames from 'classnames';

import { CarouselType, StorefrontCarouselBanner } from './index';
import { CarouselTile } from './components/tile';
import { LoadingSkeleton } from '../Loader/Skeleton';

type LoaderProps = {
  title?: string;
  type: CarouselType;
};

export const Loader = ({ title, type }: LoaderProps) => {
  const items = type === 'categories' ? 7 : 5;
  const emptyTileArray = new Array(items).fill(undefined);

  return (
    <div className="storefront-carousel d-flex flex-column gap-24">
      <div className="header d-flex justify-content-between align-items-center">
        <div>
          <h2 className="title title-l text-primary margin-0">
            {title || <LoadingSkeleton width="200px" />}
          </h2>
        </div>
        <div className="d-flex arrows gap-16">
          <LoadingSkeleton width="40px" height="40px" />
          <LoadingSkeleton width="40px" height="40px" />
        </div>
      </div>
      <div
        className={classNames('slider d-flex', {
          'gap-24': type !== 'subcategories',
          'gap-12': type === 'subcategories'
        })}>
        {emptyTileArray.map((item, id) => (
          <div key={id} data-type={type} className="item">
            {CarouselTile(
              type,
              item as unknown as StorefrontCarouselBanner,
              true,
              false,
              0
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
