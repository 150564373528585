'use client';

import Image from 'next/image';
import { useState } from 'react';

import { ImageType } from '../../models/types';
import { imgPath } from '../../utils/imgPath';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CTAButton } from '../CTAButton';

import './redirect-search.scss';
import { Markdown } from '../Markdown';

export type RedirectSearchProps = {
  anchor?: string;
  backgroundImage: ImageType;
  buttonText: string;
  heading: string;
  inputPlaceholder: string;
  searchUrl: string;
};

const redirect = (base_url: string, input: string) => {
  let url = base_url;
  url = url.replace(':sub', input);
  typeof window !== 'undefined' ? window.open(`${url}`, '_self') : null;
};

export const RedirectSearch = ({
  anchor = 'search',
  backgroundImage,
  buttonText,
  heading,
  inputPlaceholder,
  searchUrl
}: RedirectSearchProps): JSX.Element | null => {
  const [input, setInput] = useState('');

  return (
    <PackageComponentWrapper>
      <section id={anchor} data-test="redirect-search">
        <div className="redirect-search">
          <Image
            src={imgPath(backgroundImage.url)}
            width={backgroundImage.width}
            height={backgroundImage.height}
            alt={backgroundImage.alternativeText || ''}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          <div className="redirect-search-content container-lr my-2 my-sm-4">
            <div className="heading">
              <Markdown content={heading} />
            </div>
            <div id="search">
              <div className="input-class">
                <input
                  data-test="search-input"
                  aria-label="Batch Number"
                  id="homeInput"
                  type="text"
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && input.length >= 4) {
                      redirect(searchUrl, input);
                    }
                  }}
                  className="home-input"
                  placeholder={inputPlaceholder}
                />
                <CTAButton
                  callBackFunc={() => redirect(searchUrl, input)}
                  text={buttonText}
                  variant="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PackageComponentWrapper>
  );
};
