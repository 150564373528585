import { LoadingProps } from '.';
import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = ({ view = 'grid' }: LoadingProps) => {
  return (
    <div
      className={`product-tile-container view-${view}`}
      data-test="product-tile">
      <div className="tile padding-top-16 padding-left-16 padding-right-16">
        <div className="left-col">
          <span className="image">
            <LoadingSkeleton
              width={view === 'list' ? '120px' : '100%'}
              height={view === 'list' ? '120px' : '100%'}
            />
          </span>
          <div className="product-details">
            {view === 'grid' ? (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <LoadingSkeleton width="68px" height="41px" />
                </div>
                <div data-test="brand" className="margin-top-8">
                  <span className="text-black body-l font-medium">
                    <LoadingSkeleton width="40%" />
                  </span>
                </div>
                <span>
                  <h2 className="margin-top-8 product-name text-black margin-0 body-xl">
                    <LoadingSkeleton width="100%" />
                    <LoadingSkeleton
                      width="70%"
                      additionalClass="margin-top-4"
                    />
                  </h2>
                </span>
                <div className="product-options margin-top-8">
                  <span className="single-variant body-l font-bold text-black">
                    <LoadingSkeleton width="44px" />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div data-test="brand" className="margin-bottom-4">
                  <span className="body-l">
                    <LoadingSkeleton width="40%" />
                  </span>
                </div>
                <span>
                  <h2 className="product-name margin-0 body-xl">
                    <LoadingSkeleton width="100%" />
                    <LoadingSkeleton
                      width="70%"
                      additionalClass="margin-top-4"
                    />
                  </h2>
                </span>
                <div className="d-flex justify-content-between align-items-center margin-top-4">
                  <LoadingSkeleton width="44px" />
                </div>
              </>
            )}
            <div className="product-additional-info">
              <span className="body-sm">
                <LoadingSkeleton width="100%" />
                <LoadingSkeleton width="30%" additionalClass="margin-top-4" />
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex right-col">
          {view === 'list' ? (
            <div>
              <div className="product-options">
                <span className="single-variant body-l font-bold text-black">
                  <LoadingSkeleton />
                </span>
              </div>
            </div>
          ) : null}
          <div className="cart-button-wrapper">
            <LoadingSkeleton
              width="100%"
              height={view === 'list' ? '56px' : '40px'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
