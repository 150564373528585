'use client';

import { useEffect, useState } from 'react';

import { useStorage } from '../hooks/useStorage';
import { Alert, type AlertProps } from '../components/Alert';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { DutchiePlusHomePage } from './homePage';
import { StorefrontBannersProps } from '../components/StorefrontBanners';
import { MappedProductList } from '../utils/storefrontMap';
import { ImageType } from '../models/types';

export type FavoredBrands = {
  brand_name: string;
  icon: ImageType;
  slug: string | null;
};

export type StorefrontPageProps = {
  productLists: {
    RECREATIONAL: MappedProductList[];
    MEDICAL: MappedProductList[];
  };
  storefrontBanners: StorefrontBannersProps['banners'];
};

export const StorefrontPage = ({
  productLists,
  storefrontBanners
}: StorefrontPageProps) => {
  const { alerts, currentPathDispensary } = useDispensaryPathContext();
  const { userMenuType } = useSiteWideContext();
  const [ageConfirm] = useStorage(
    'confirmed21OrOlder',
    '0',
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct') || false
  );
  const [promptedForAlert, setPromptedForAlert] = useStorage(
    'promptedForAlert',
    JSON.stringify({})
  );

  const [nextAlert, setNextAlert] = useState<AlertProps | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [toggleAlertOpen, toggleAlert] = useState<boolean>(false);

  useEffect(() => {
    if (userMenuType) {
      if (currentPathDispensary) {
        try {
          if (ageConfirm === '1' && alerts.length) {
            let upcomingAlert;
            const jsonPrompt = JSON.parse(promptedForAlert);
            for (const alert of alerts) {
              const alertSlug = currentPathDispensary
                ? currentPathDispensary.slug || '' + alert?.id
                : '';
              const hasRun = jsonPrompt[alertSlug] === true;
              if (!hasRun) {
                upcomingAlert = alert;
                setPromptedForAlert(
                  JSON.stringify({ ...jsonPrompt, [alertSlug]: true })
                );
                break;
              }
            }
            if (upcomingAlert) setNextAlert(upcomingAlert);
            toggleAlert(true);
            if (upcomingAlert) {
              upcomingAlert.button.callBackFunc = () => toggleAlert(false);
              setAlert(upcomingAlert);
            }
          }
        } catch (err: unknown) {
          if (err instanceof Error) console.error(err.message);
        }
      }
    }
  }, [ageConfirm, userMenuType, currentPathDispensary]);

  return (
    <>
      {alert && nextAlert && toggleAlertOpen ? <Alert {...alert} /> : null}
      {currentPathDispensary.storefrontMap?.__typename ===
      'DispensaryConfigStorefrontDutchiePlus' ? (
        <DutchiePlusHomePage
          storefrontBanners={storefrontBanners}
          productLists={productLists}
        />
      ) : null}
    </>
  );
};
