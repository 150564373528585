'use client';

import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import {
  DatalayerAnalytics,
  MarketingFormDataType,
  StatesNDispensaries
} from 'services';

import { CTAButtonProps } from '../CTAButton';
import { CTAButton } from '../CTAButton';
import { TextInput } from './components/TextInput';
import { CheckboxSelection } from './components/Checkbox';
import { DropdownSelection } from './components/DropdownSelection';
import { FormConfirmation } from './components/Confirmation';
import { FormDisclaimer } from './components/Disclaimer';

import { isEmailValid } from '../../utils/validators';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { marketingIntakeMap } from '../../utils/formMap';
import { ImageType } from '../../models/types';

import './embeddable-form.scss';

export type MarketingIntakeProps = {
  brazeInstance?: string;
  buttonText: string;
  closeButton?: Pick<CTAButtonProps, 'callBackFunc' | 'text' | 'variant'>;
  confirmation: {
    callback?: () => void;
    content: string;
    image?: ImageType | null;
    title: string;
  };
  disclaimer?: string;
  formUid: string;
  image?: ImageType | null;
  optInContent?: string;
  statesNdispensaries: StatesNDispensaries[];
  subtitle?: string;
  title: string;
};

const FORM_GA_TYPE = 'marketing_intake';

export const MarketingIntakeForm = ({
  brazeInstance,
  buttonText,
  closeButton,
  confirmation,
  disclaimer,
  formUid,
  optInContent = 'I would like to receive the latest news and info from Curaleaf',
  statesNdispensaries,
  subtitle,
  title
}: MarketingIntakeProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    DatalayerAnalytics.pushBasicEvent('form_view', {
      form_type: FORM_GA_TYPE
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      state:
        statesNdispensaries && statesNdispensaries.length === 1
          ? statesNdispensaries[0]?.state || ''
          : '',

      dispensary: '',
      optInMethod: ['Email'],
      optIn: true,
      formUid,
      brazeInstance
    },
    validate: (data: MarketingFormDataType) => {
      const errors = {} as MarketingFormDataType;

      if (!data.email) {
        errors.email = 'Email is required.';
      } else if (!isEmailValid(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }
      if (statesNdispensaries.length && !data.state) {
        errors.state = 'State is required.';
      }
      if (!data.optIn) {
        errors.optIn =
          'Opting In is required if you are signing up for our mailing list.';
      }

      return errors;
    },
    onSubmit: async (values: MarketingFormDataType) => {
      DatalayerAnalytics.pushBasicEvent('form_submit', {
        form_type: FORM_GA_TYPE
      });
      setLoading(true);
      try {
        const submit = await axios.post(
          '/api/form/submit',
          marketingIntakeMap(values)
        );
        if (submit.status === 200) {
          setShowConfirmation(true);
          setLoading(false);
          formik.resetForm();
        }
      } catch (err) {
        setLoading(false);
        console.warn(`Error: ${err}`);
      }
    }
  });

  const matchingState = statesNdispensaries.find(
    (state) => state.state === formik.values.state
  );

  return (
    <ErrorBoundary component="marketing-intake-form">
      <FormConfirmation
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        confirmation={confirmation}
      />
      <form
        onSubmit={formik.handleSubmit}
        className="embeddable-form-curaleaf container-lr padding-bottom-16">
        <div className="margin-bottom-32">
          <h1 className="text-primary h3 margin-bottom-10">{title}</h1>
          {subtitle ? (
            <p className="margin-top-10 margin-bottom-0">{subtitle}</p>
          ) : null}
        </div>
        <div>
          <TextInput
            title="Email"
            type="email"
            field="email"
            formik={formik}
            required={true}
            autoComplete="email"
            customId="marketing-intake-email"
          />
          {statesNdispensaries && statesNdispensaries.length > 1 ? (
            <DropdownSelection
              title="What is your preferred state to shop in?"
              dropdown_options={statesNdispensaries.map((state) => state.state)}
              field="state"
              sort={true}
              formik={formik}
              required={true}
            />
          ) : null}
          {matchingState && matchingState.dispensaries.length ? (
            <DropdownSelection
              title="What dispensary will you most likely visit?"
              dropdown_options={matchingState.dispensaries.map(
                (dispensary) => dispensary.name
              )}
              field="dispensary"
              sort={true}
              formik={formik}
            />
          ) : null}
          <CheckboxSelection
            checkbox_options={{
              label: `${optInContent}`
            }}
            field="optIn"
            formik={formik}
            required={true}
          />
          <div className="margin-bottom-16">
            <FormDisclaimer disclaimer={disclaimer} />
          </div>
          <div className={closeButton ? 'button-group' : 'button'}>
            <CTAButton
              type="submit"
              loading={loading}
              text={buttonText}
              variant="primary"
              fullWidth={true}
            />
            {closeButton ? (
              <CTAButton
                callBackFunc={closeButton.callBackFunc}
                text={closeButton.text}
                variant={closeButton.variant}
                fullWidth={true}
              />
            ) : null}
          </div>
        </div>
      </form>
    </ErrorBoundary>
  );
};
