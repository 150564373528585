import moment from 'moment';
import {
  Consolidate_Dispensary,
  CS_Dispensary_Storefront,
  CS_Dispensary,
  CurrentPathDispensary,
  DutchiePlus_OrderType,
  DutchiePlus_Retailer,
  OverviewPageDispensary,
  SiteWideDispensary,
  slugify,
  StoreCardDispensary,
  StoreOffers
} from 'services';
import { optionalImageMap } from './contentstackMap';

export const dispensaryMap = (
  cs: CS_Dispensary,
  dp: DutchiePlus_Retailer | undefined,
  type:
    | 'currentPathDispensary'
    | 'locations'
    | 'overview'
    | 'siteWideDispensary'
):
  | CurrentPathDispensary
  | OverviewPageDispensary
  | SiteWideDispensary
  | StoreCardDispensary
  | null => {
  if (!cs) {
    return null;
  } else if (
    (type === 'currentPathDispensary' || type === 'siteWideDispensary') &&
    !dp
  ) {
    return null;
  }

  const isExternal =
    cs.storefront[0] &&
    cs.storefront[0].__typename === 'DispensaryConfigStorefrontExternal'
      ? cs.storefront[0].external
      : undefined;
  false;
  const externalUrl = isExternal ? isExternal.url : undefined;
  const splitExternalUrl = isExternal?.split_url ?? false;

  switch (type) {
    case 'currentPathDispensary': {
      const dispensaryData: CurrentPathDispensary = {
        deliveryDisclaimer: cs.delivery?.disclaimer,
        deliveryOnly: !!cs.delivery?.delivery_only,
        externalUrl,
        friendlyName: cs.friendly_name,
        isPrerelease:
          cs.storefront[0]?.__typename ===
          'DispensaryConfigStorefrontPreRelease',
        googleMapsUri: cs.google_maps_uri,
        location: {
          address: cs.address,
          city: cs.city,
          coordinates: {
            latitude: parseFloat(cs.latitude),
            longitude: parseFloat(cs.longitude)
          },
          distance: '',
          state: cs.state,
          zipcode: cs.zipcode
        },
        mapImage: optionalImageMap(cs.map_imageConnection),
        menuTypes:
          !dp || isExternal
            ? cs.dispensary_type === 'Both'
              ? ['MEDICAL', 'RECREATIONAL']
              : cs.dispensary_type === 'MedicalOnly'
                ? ['MEDICAL']
                : ['RECREATIONAL']
            : dp.menuTypes,
        openTimes: mappedOpenTimes(cs),
        orderTypes:
          isExternal || !dp
            ? cs.store_offerings.includes('Delivery')
              ? ['DELIVERY']
              : []
            : getFulfillmentTypes(cs, dp),
        pauseEcommerce: cs.admin?.pause_ecommerce
          ? cs.admin.disabled_button_text || 'Temporarily Disabled'
          : '',
        retailerId: cs.app_config?.dutchie_retailer_id,
        shopLink: slugify(`/shop/${cs.state.slug}/${cs.slug}`),
        slug: cs.slug,
        storefrontMap: storefrontMap(cs.storefront[0]),
        todaysHours: todaysHours(mappedOpenTimes(cs)),
        uid: cs.uid,
        type: 'currentPathDispensary'
      };
      return dispensaryData;
    }
    case 'locations': {
      const dispensaryData: StoreCardDispensary = {
        deliveryOnly: !!cs.delivery?.delivery_only,
        externalUrl,
        friendlyName: cs.friendly_name,
        isPrerelease:
          cs.storefront[0]?.__typename ===
          'DispensaryConfigStorefrontPreRelease',
        location: {
          address: cs.address,
          city: cs.city,
          coordinates: {
            latitude: parseFloat(cs.latitude),
            longitude: parseFloat(cs.longitude)
          },
          distance: cs.distance,
          state: cs.state,
          zipcode: cs.zipcode
        },
        menuTypes:
          !dp || isExternal
            ? cs.dispensary_type === 'Both'
              ? ['MEDICAL', 'RECREATIONAL']
              : cs.dispensary_type === 'MedicalOnly'
                ? ['MEDICAL']
                : ['RECREATIONAL']
            : dp.menuTypes,
        openTimes: mappedOpenTimes(cs),
        orderTypes:
          isExternal || !dp
            ? cs.store_offerings.includes('Delivery')
              ? ['DELIVERY']
              : []
            : getFulfillmentTypes(cs, dp),
        shopLink: slugify(`/shop/${cs.state.slug}/${cs.slug}`),
        slug: cs.slug,
        splitExternalUrl,
        todaysHours: todaysHours(mappedOpenTimes(cs)),
        type: 'locations',
        uid: cs.uid,
        timesets:
          cs.timesets?.map((set) => {
            return {
              title: set.display_title,
              openTimes: mappedOpenTimes(cs, JSON.parse(set.time_data)),
              todaysHours: todaysHours(
                mappedOpenTimes(cs, JSON.parse(set.time_data))
              )
            };
          }) ?? []
      };
      return dispensaryData;
    }
    case 'overview': {
      const dispensaryData: OverviewPageDispensary = {
        externalUrl,
        friendlyName: cs.friendly_name,
        location: {
          address: cs.address,
          city: cs.city,
          coordinates: {
            latitude: parseFloat(cs.latitude),
            longitude: parseFloat(cs.longitude)
          },
          distance: '',
          zipcode: cs.zipcode,
          state: cs.state
        },
        googleMapsUri: cs.google_maps_uri,
        menuTypes:
          !dp || isExternal
            ? cs.dispensary_type === 'Both'
              ? ['MEDICAL', 'RECREATIONAL']
              : cs.dispensary_type === 'MedicalOnly'
                ? ['MEDICAL']
                : ['RECREATIONAL']
            : dp.menuTypes,
        openTimes: mappedOpenTimes(cs),
        overview: cs.dispensary_overview_override?.length
          ? cs.dispensary_overview_override
          : cs.overview,
        overviewPhotos: cs.overview_photos
          ? [...cs.overview_photos].map((photo, id) => ({
              url: photo,
              id
            }))
          : [],
        phone: cs.phone,
        shopLink: slugify(`/shop/${cs.state.slug}/${cs.slug}`),
        slug: cs.slug,
        splitExternalUrl,
        storeOffers: storeOfferMap(
          cs.store_offerings,
          cs.debit_fee_text,
          cs.delivery?.dispensary_type_delivery
        ),
        uid: cs.uid,
        type: 'overview',
        timesets:
          cs.timesets?.map((set) => {
            return {
              title: set.display_title,
              openTimes: mappedOpenTimes(cs, JSON.parse(set.time_data)),
              todaysHours: todaysHours(
                mappedOpenTimes(cs, JSON.parse(set.time_data))
              )
            };
          }) ?? []
      };
      return dispensaryData;
    }
    case 'siteWideDispensary': {
      const dispensaryData: SiteWideDispensary = {
        deliveryOnly: !!cs.delivery?.delivery_only,
        extraFooterText: cs.extra_footer_text,
        friendlyName: cs.friendly_name,
        location: {
          address: cs.address,
          city: cs.city,
          coordinates: {
            latitude: parseFloat(cs.latitude),
            longitude: parseFloat(cs.longitude)
          },
          distance: '',
          state: cs.state,
          zipcode: cs.zipcode
        },
        menuTypes:
          !dp || isExternal
            ? cs.dispensary_type === 'Both'
              ? ['MEDICAL', 'RECREATIONAL']
              : cs.dispensary_type === 'MedicalOnly'
                ? ['MEDICAL']
                : ['RECREATIONAL']
            : dp.menuTypes,
        orderTypes:
          isExternal || !dp
            ? cs.store_offerings.includes('Delivery')
              ? ['DELIVERY']
              : []
            : getFulfillmentTypes(cs, dp),
        pauseEcommerce: cs.admin?.pause_ecommerce
          ? cs.admin.disabled_button_text || 'Temporarily Disabled'
          : '',
        retailerId: cs.app_config.dutchie_retailer_id,
        shopLink: slugify(`/shop/${cs.state.slug}/${cs.slug}`),
        slug: cs.slug,
        uid: cs.uid,
        type: 'siteWideDispensary'
      };
      return dispensaryData;
    }
    default: {
      return null;
    }
  }
};

type IconCategory =
  | 'HC'
  | 'PARKING'
  | 'CASH'
  | 'DEBIT'
  | 'PICKUP'
  | 'INSTORE'
  | 'DELIVERY'
  | '';

const CSOfferingOptions: { [key: string]: IconCategory } = {
  'Curbside Pickup': 'PICKUP',
  Delivery: 'DELIVERY',
  'Point of Sale ATM': 'DEBIT',
  'In Store': 'INSTORE',
  'Express pick-up': 'PICKUP',
  Cash: 'CASH',
  'Parking Available': 'PARKING',
  'Handicap Accessible': 'HC',
  'ATM on-site': 'DEBIT',
  'Drive Through': 'PICKUP',
  CanPay: 'CASH',
  'Pin Debit': 'DEBIT'
};

const catWeights: { [key: string]: number } = {
  HC: 3,
  PARKING: 4,
  CASH: 1,
  DEBIT: 2,
  PICKUP: 5,
  INSTORE: 6
};

const storeOfferMap = (
  offerings: CS_Dispensary['store_offerings'],
  debitFee: string,
  delivery?: string
): StoreOffers[] =>
  offerings && offerings.length
    ? [...offerings]
        .filter((o) => {
          return (
            o.toLowerCase() !== 'debit' && o.toLowerCase() !== 'atm on site'
          );
        })
        .map((o, i) => {
          let text: string = o;
          if (o === 'Delivery' && delivery) {
            text = `${
              delivery === 'RECREATIONAL' ? 'Adult-Use' : 'Medical'
            } Delivery`;
          }
          if (o === 'Point of Sale ATM' || o === 'Pin Debit') {
            text = `${o} ${debitFee || ''}`;
          }
          return {
            id: i.toString(),
            text,
            type: CSOfferingOptions[o] || ''
          };
        })
        .sort((a, b) => {
          const aRank = catWeights[a.type] || 0;
          const bRank = catWeights[b.type] || 0;
          return aRank - bRank;
        })
    : [];

const getFulfillmentTypes = (
  cs: CS_Dispensary,
  dp: DutchiePlus_Retailer
): DutchiePlus_OrderType[] => {
  const fulfillmentTypes: DutchiePlus_OrderType[] = [];
  if (dp) {
    if (dp.fulfillmentOptions.curbsidePickup)
      fulfillmentTypes.push('CURBSIDE_PICKUP');
    if (cs.delivery?.dispensary_type_delivery) {
      const type =
        cs.delivery?.dispensary_type_delivery === 'RECREATIONAL'
          ? 'AU_DELIVERY'
          : 'MED_DELIVERY';
      fulfillmentTypes.push(type);
    } else if (cs.store_offerings.includes('Delivery')) {
      fulfillmentTypes.push('DELIVERY');
    }
    if (dp.fulfillmentOptions.driveThruPickup)
      fulfillmentTypes.push('DRIVE_THRU_PICKUP');
    if (dp.fulfillmentOptions.pickup) fulfillmentTypes.push('PICKUP');
  }

  return fulfillmentTypes;
};

export const sortDispensaries = (
  a: Pick<Consolidate_Dispensary, 'friendlyName'>,
  b: Pick<Consolidate_Dispensary, 'friendlyName'>
): number => {
  if (a.friendlyName > b.friendlyName) return 1;
  if (a.friendlyName < b.friendlyName) return -1;
  return 0;
};

type DayTime = {
  endTime?: string;
  startTime?: string;
};

const mappedOpenTimes = (
  store: CS_Dispensary,
  timeset?: { [day: string]: string[] }
) => {
  const daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];
  return daysOfWeek.reduce(
    (acc: StoreCardDispensary['openTimes'], day: string) => {
      const value = (timeset || store.day_times)[
        `${day}Times` as keyof CS_Dispensary['day_times']
      ] as CS_Dispensary['day_times'][keyof CS_Dispensary['day_times']];
      if (!value) return acc;
      (acc[day as keyof StoreCardDispensary['openTimes']] as DayTime[]) =
        value.map(([a, b]) => ({
          startTime: militaryToNormal(a),
          endTime: militaryToNormal(b),
          militaryStartTime: a,
          militaryEndTime: b
        }));
      return acc;
    },
    {} as StoreCardDispensary['openTimes']
  );
};

const todaysHours = (
  times: StoreCardDispensary['openTimes']
): string | undefined => {
  if (!times) return undefined;

  const today = moment()
    .format('dddd')
    .toLowerCase() as keyof StoreCardDispensary['openTimes'];
  if (!(today in times) || !times?.[today].length) return 'Closed';

  const results = times?.[today]
    .map((st) => {
      if (st.startTime && st.endTime) {
        return st.startTime === st.endTime
          ? '24 Hours'
          : `${st.startTime} - ${st.endTime}`;
      }
      return 'Closed';
    })
    .filter(Boolean)
    .join(', ');
  return results || 'Closed';
};

const militaryToNormal = (time?: string) => {
  if (!time) return '';
  const parsedTime = moment(time, 'HH:mm');
  if (!parsedTime.isValid()) return '';
  return parsedTime.format('h:mm a');
};

const storefrontMap = (
  storefront?: CS_Dispensary_Storefront
): Consolidate_Dispensary['storefrontMap'] | null => {
  if (
    storefront &&
    storefront.__typename === 'DispensaryConfigStorefrontDutchiePlus'
  ) {
    const sf = storefront.Dutchie_Plus?.storefrontConnection.edges[0]?.node;

    return {
      __typename: storefront.__typename,
      feedbackUrl:
        storefront.Dutchie_Plus.storefrontConnection?.edges[0]?.node
          .survey_monkey_feedback_url || '',
      ctaBanners: sf?.cta_banner.length
        ? sf.cta_banner.map((ctab, id) => {
            return {
              id: id,
              link: {
                id: id,
                url: ctab.url,
                desktop_text: ctab.desktop_text,
                mobile_text: ctab.mobile_text
              }
            };
          })
        : [],
      hideFlowerEquivalent:
        sf && sf.hide_flower_equivalent ? sf.hide_flower_equivalent : false
    };
  }
  return null;
};
