'use client';

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { getSlug } from 'services';
import { ecommerceSanitizeQuery, isExternalUrl } from '../../utils/url';

import XSCart from '../../assets/svgs/product/cart-xs.svg';
import Cart from '../../assets/svgs/product/cart.svg';

import './cta-button.scss';

export const changeButtonText = (text: string) => {
  // Limits button text to 6 words
  let newWord = '';
  const wordLimit = 6;
  const words = text.split(' ');
  words.length > wordLimit
    ? (newWord = words.slice(0, 6).join(' '))
    : (newWord = text);

  return newWord;
};

export type CTAButtonVariants = 'primary' | 'secondary';

export type CTAButtonProps = {
  allowEmptyUrl?: boolean;
  callBackFunc?: (e?: any) => void | Promise<void> | undefined;
  'data-testid'?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  fullWidthMobile?: boolean;
  loading?: boolean;
  showCartIcon?: boolean;
  size?: 'sm' | 'md' | 'lg';
  text: string;
  type?: 'submit' | 'button';
  url?: string | null;
  variant?: CTAButtonVariants;
};

export const CTAButton = ({
  allowEmptyUrl = false,
  callBackFunc,
  'data-testid': dataTestId = 'cta-button',
  disabled = false,
  fullWidth = false,
  fullWidthMobile = true,
  loading = false,
  showCartIcon = false,
  size = 'lg',
  text,
  type,
  url,
  variant = 'primary'
}: CTAButtonProps): JSX.Element | null => {
  const styles: {
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  } = {};
  const [isDisabled, setDisabled] = useState(disabled);

  useEffect(() => {
    if (disabled !== isDisabled) {
      setDisabled(disabled);
    }
  }, [disabled]);

  const anchor = (anchorUrl: string) => {
    return (
      <Link
        tabIndex={0}
        className={classNames(`btn ${size} cta-button-${variant}`, {
          'cta-button-disabled': isDisabled,
          [`cta-button-${variant}`]: !isDisabled
        })}
        onClick={(e) => (callBackFunc ? callBackFunc(e) : null)}
        aria-disabled={isDisabled}
        data-testid={dataTestId}
        target={anchorUrl && isExternalUrl(anchorUrl) ? '_blank' : ''}
        rel="noreferrer"
        title={text}
        href={`${ecommerceSanitizeQuery(getSlug(anchorUrl))}`}
        style={styles}
        prefetch={false}>
        {text && changeButtonText(text)}
      </Link>
    );
  };

  const cbButton = (cb: (e: any) => void) => (
    <button
      type={type ? type : 'button'}
      onClick={(e) => {
        cb(e);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          cb(event);
        }
      }}
      className={classNames(`btn d-flex justify-content-center ${size}`, {
        'cta-button-disabled': isDisabled,
        [`cta-button-${variant}`]: !isDisabled
      })}
      data-testid={dataTestId}
      style={styles}>
      {showCartIcon && size === 'sm' ? (
        <XSCart />
      ) : (showCartIcon && size === 'md') || (showCartIcon && size === 'lg') ? (
        <Cart />
      ) : null}
      {text && changeButtonText(text)}
    </button>
  );

  const formButton = () => (
    <button
      type="submit"
      className={classNames(`btn d-flex justify-content-center ${size}`, {
        'cta-button-disabled': isDisabled,
        [`cta-button-${variant}`]: !isDisabled
      })}
      data-testid={dataTestId}
      style={styles}>
      {text && changeButtonText(text)}
    </button>
  );

  return (
    <div
      className={`cta-button cta-button-curaleaf`}
      data-full-width={fullWidth}
      data-full-width-mobile={fullWidthMobile}>
      {loading ? (
        <div className={`spinner-icon cta-button-${variant} ${size}`}>
          <div className="loading-spinner" />
        </div>
      ) : url ? (
        anchor(url)
      ) : !url && callBackFunc ? (
        cbButton(callBackFunc)
      ) : type === 'submit' ? (
        formButton()
      ) : !url && !callBackFunc && allowEmptyUrl ? (
        <Link
          href=""
          className={classNames(`btn ${size}`, {
            'cta-button-disabled': isDisabled,
            [`cta-button-${variant}`]: !isDisabled
          })}
          aria-disabled={isDisabled}
          data-testid={dataTestId}
          title={text}
          style={styles}>
          {text && changeButtonText(text)}
        </Link>
      ) : null}
    </div>
  );
};
