'use client';

import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { useCarousel } from '../../hooks/useCarousel';

import Carousel from '../Carousel';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CarouselDots } from '../Carousel/components';
import { CTAButton } from '../CTAButton';

import './store-info-carousel.scss';

export type StoreInfoBannerProps = {
  cta?: { text: string; url: string } | null;
  heading: string;
  id: number;
  text: string;
};

export type StoreInfoCarouselProps = {
  banners: StoreInfoBannerProps[];
};

export const StoreInfoCarousel = ({ banners }: StoreInfoCarouselProps) => {
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex
  } = useCarousel(banners, true, 15);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex
  };

  return (
    <ErrorBoundary component="store-info-carousel">
      <PackageComponentWrapper>
        <div
          className="specials-carousel"
          data-test="specials-carousel-container">
          <div className="carousel-container">
            <Carousel
              data={banners}
              states={states}
              data-test="category-carousel">
              {banners.map((s) => {
                return (
                  <div
                    data-test="carousel-item"
                    key={s.id}
                    className="carousel-el"
                    style={{
                      transform: `translate3d(${
                        -activeImageIndex * 100
                      }%, 0, 0)`
                    }}>
                    <div className="store-info-carousel bg-tertiary-primary">
                      <h5 className="text-primary body-xl">{s.heading}</h5>
                      <p>{s.text}</p>
                      {s.cta ? (
                        <CTAButton
                          {...s.cta}
                          variant="primary"
                          fullWidth={true}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <div className="storefront-banner-dots thumbs">
              {banners && banners.length > 1 && (
                <CarouselDots data={banners} states={states} dotName="sm-dot" />
              )}
            </div>
          </div>
        </div>
      </PackageComponentWrapper>
    </ErrorBoundary>
  );
};
