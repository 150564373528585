'use client';

import { Dialog } from 'primereact/dialog';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { CTAButton } from '../../../CTAButton';

import Logo from '../../../../assets/svgs/header/full-logo-teal.svg';

import './clear-dispensary-modal.scss';

export const KioskClearDispensaryModal = (): JSX.Element | null => {
  const [show, setShow] = useState(false);
  const [clicks, setClicks] = useState<number[]>([]);
  const { push } = useRouter();

  return (
    <>
      {show ? (
        <Dialog
          className="clear-dispensary-modal disable-scroll"
          draggable={false}
          header={<h2 className="text-error-dark h3">Clear dispensary?</h2>}
          footer={
            <div className="button-group">
              <CTAButton
                text="Clear"
                variant="secondary"
                callBackFunc={() => {
                  setShow(false);
                  push('/?clear=true');
                }}
              />
              <CTAButton
                text="Cancel"
                variant="primary"
                callBackFunc={() => setShow(false)}
              />
            </div>
          }
          onHide={() => setShow(false)}
          resizable={false}
          visible={show}
        />
      ) : null}
      <button
        className="clear-dispensary-button padding-inline-5"
        onClick={() => {
          const now = new Date().getTime();
          const currClicks = [...clicks, now];
          const threeClicks =
            currClicks.length > 3
              ? currClicks.slice(currClicks.length - 3)
              : currClicks;
          setClicks(threeClicks);
          if (threeClicks.filter((click) => now - click <= 3000).length === 3) {
            setShow(true);
          }
        }}>
        <Logo />
      </button>
    </>
  );
};
