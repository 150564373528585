'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import StoreOffers from './StoreOffers';
import StoreInformation from './StoreInformation';
import StoreOpeningHours from './StoreOpeningHours';
import { CTAButton } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import StoreImageCarousel from './StoreImageCarousel';
import { BreadCrumbs } from '../Breadcrumbs';
import { overviewJsonLD } from '../../utils/jsonLD';
import { DatalayerAnalytics, OverviewPageDispensary } from 'services';
import { PageState } from '../PageState';
import { StorefrontCarousel } from '../StorefrontCarousel';
import { CategoryLink } from '../../hooks/siteWideContext';

import './dispensary-overview.scss';

export type DispensaryOverviewProps = {
  dispensary?: OverviewPageDispensary;
  categories?: CategoryLink[];
};

export const DispensaryOverview = ({
  dispensary,
  categories
}: DispensaryOverviewProps): JSX.Element | null => {
  const pathname = usePathname();

  const breadcrumb = [
    {
      id: 0,
      urlFragment: '/',
      breadcrumbName: 'Home'
    },
    {
      id: 1,
      urlFragment: '/locations',
      breadcrumbName: 'All Locations'
    }
  ];

  if (dispensary) {
    breadcrumb.push({
      id: 2,
      urlFragment: `/dispensary/${dispensary.location.state.slug}`,
      breadcrumbName: `${dispensary.location.state.title} Locations`
    });
  }

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'shop/details',
      consolidateDispensary: dispensary
    });
  }, [pathname]);

  if (!dispensary) {
    return (
      <PageState
        header={'Dispensary not found.'}
        link={{
          url: '/locations',
          text: 'See our other locations!'
        }}
      />
    );
  }

  return (
    <>
      <section>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              overviewJsonLD(dispensary, breadcrumb, pathname)
            )
          }}
        />
      </section>
      <BreadCrumbs
        props={{
          breadcrumb,
          currentPage: dispensary.friendlyName,
          isLoading: false
        }}
      />
      <PackageComponentWrapper>
        <div className="dispensary-overview">
          {/* STORE INFORMATION */}
          <div className="store-information d-flex br-8">
            <div
              data-no-images={!!dispensary.overviewPhotos.length}
              className="text-container bg-white padding-32">
              <div className="store-details d-flex flex-column justify-content-center">
                <h1 className="text-primary h3 margin-bottom-0">
                  {dispensary.friendlyName || ''}
                </h1>
                <StoreInformation
                  address={`${dispensary.location.address}, ${dispensary.location.city}, ${dispensary.location.state.abbreviation} ${dispensary.location.zipcode}`}
                  googleMapsUri={dispensary.googleMapsUri}
                  number={dispensary.phone}
                  url={`/contact-us?dispensary=${dispensary.friendlyName}&state=${dispensary.location.state.title}`}
                />
                <StoreOpeningHours
                  times={dispensary.openTimes}
                  timeSets={dispensary.timesets}
                />
                {dispensary.splitExternalUrl ? (
                  <div className="split-menu-buttons d-flex flex-row">
                    {['recreational', 'medical'].map((mt) => {
                      const text =
                        mt === 'recreational'
                          ? 'Shop Adult-Use'
                          : 'Shop Medical';
                      return (
                        <CTAButton
                          key={mt}
                          fullWidth={true}
                          fullWidthMobile={true}
                          text={text}
                          url={`${dispensary.externalUrl}/${mt}`}
                          variant="primary"
                        />
                      );
                    })}
                  </div>
                ) : (
                  <CTAButton
                    fullWidth={true}
                    text="Shop This Store"
                    variant="primary"
                    url={dispensary.externalUrl || dispensary.shopLink}
                  />
                )}
              </div>
            </div>
            <div
              data-no-images={!!dispensary.overviewPhotos.length}
              className="image-container">
              <StoreImageCarousel banners={dispensary.overviewPhotos} />
            </div>
          </div>
          {/* STORE OVERVIEW */}
          <div className="store-overview d-flex flex-column padding-32">
            <h1 className="text-primary h3 margin-bottom-0">
              Dispensary Overview
            </h1>
            <p>{dispensary.overview}</p>
            <StoreOffers offers={dispensary.storeOffers} />
          </div>
        </div>
      </PackageComponentWrapper>
      {categories?.length ? (
        <div className="container-lr">
          <StorefrontCarousel
            banners={categories}
            isLoading={false}
            title="Categories"
            type="categories"
          />
        </div>
      ) : null}
    </>
  );
};
