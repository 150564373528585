import { Dispatch } from 'react';

import {
  DatalayerAnalytics,
  DutchiePlus_PricingType,
  ProductTileType
} from 'services';

import { ProductsData } from '@cura/next-server/GetProducts';

export type RecommendationCarousel = {
  all: ProductTileType[];
  category: ProductTileType[];
};

type PageType = 'HomePage' | 'CartPage' | 'ProductPage';

export const fetchProductsForRecommendations = async (
  websiteUrl: string,
  recommendationIds: string[],
  retailerId: string,
  defaultMenuType: DutchiePlus_PricingType,
  setRecommendations: Dispatch<RecommendationCarousel>,
  pageType: PageType,
  dispensaryUid: string
) => {
  await fetch(
    `${websiteUrl}/api/products/${retailerId}?menuType=${defaultMenuType}&pageType=${pageType}&recommendationIds=${recommendationIds.join(',')}&dispensaryUid=${dispensaryUid}`,
    { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
  )
    .then((res) => res.json())
    .then(({ data }: { data: ProductsData }) => {
      if (data.products?.length) {
        const all = data.products.filter(
          (p) => p.recommendationCarouselType === 'all'
        );
        const category = data.products.filter(
          (p) => p.recommendationCarouselType === 'category'
        );
        setRecommendations({
          all,
          category
        });
      }
    })
    .catch((error) => {
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'fetchProductsForRecommendations',
        description:
          (error as string) || 'Error fetching products for recommendations.'
      });
    });
};
