import { useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { FooterNavLinks } from '../..';
import { CTAButton } from '../../../CTAButton';
import { Markdown } from '../../../Markdown';

import './legal-dialog.scss';

export type LegalDialogProps = {
  legalLinks: FooterNavLinks[];
};

export const LegalDialog = ({
  legalLinks
}: LegalDialogProps): JSX.Element | null => {
  const [showLegalDialog, setShow] = useState(false);
  const [legalContent, setLegalContent] = useState('');

  return (
    <>
      {showLegalDialog ? (
        <Dialog
          className="legal-dialog disable-scroll"
          draggable={false}
          style={{ width: '80vw' }}
          footer={
            <CTAButton
              fullWidth={true}
              text="Close"
              variant="secondary"
              callBackFunc={() => setShow(false)}
            />
          }
          onHide={() => setShow(false)}
          resizable={false}
          visible={showLegalDialog}>
          <div className="text-black">
            <Markdown content={legalContent} />
          </div>
        </Dialog>
      ) : null}
      {legalLinks.length ? (
        <ul className="footer-link-list d-flex justify-content-center gap-16 padding-0 margin-top-16">
          {legalLinks[0]?.links.map((footerLink) => {
            return (
              <li key={footerLink.id}>
                <a
                  tabIndex={0}
                  role="button"
                  className="text-white pointer body-l underline text-tertiary-dark body-sm"
                  key={footerLink.id}
                  onClick={() => {
                    if (footerLink.content) {
                      setLegalContent(footerLink.content);
                      setShow(true);
                    }
                  }}
                  data-test="legal-link">
                  {footerLink.linkName}
                </a>
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};
