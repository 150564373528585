import { useRouter } from 'next/navigation';

import { ProductSortFilter } from './ProductSortFilter';
import { PageState } from '../PageState';

import { EcomProductGridProps } from '.';
import { TextBlock } from '../TextBlock';
import { ProductTile } from '../ProductTile/default';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { DatalayerAnalytics } from 'services';
import { useSiteWideContext } from '../../hooks/siteWideContext';

export const EcomProductGrid = ({
  filters,
  isLoading,
  offersLength,
  onApplyFilter,
  onSelectFilter,
  pageType,
  products,
  searchParams,
  setProductView,
  shopLink,
  sortBy,
  totalProductCount,
  view
}: EcomProductGridProps): JSX.Element | null => {
  const { currentPathDispensary } = useDispensaryPathContext();
  const { addItemToCart, isMobile } = useSiteWideContext();
  const { push, refresh } = useRouter();

  if (pageType === 'AllSpecialsPage' && offersLength < 1) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'unknown',
      location: 'ecomProductGrid',
      description: 'There are currently no specials available.',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="There are currently no specials available."
        link={{
          url: shopLink,
          text: 'Check out our other products!'
        }}
      />
    );
  }

  const hasSelectedFilters = () => {
    const queries = new URLSearchParams(window.location.search);
    queries.delete('page');
    queries.delete('sort');
    queries.delete('k');
    return queries.toString().length > 0;
  };

  return (
    <div className="ecom-product-grid-wrapper d-flex flex-column gap-24">
      {filters && Object.keys(filters).length ? (
        <ProductSortFilter
          props={{
            filters,
            isLoading,
            onApplyFilter,
            onSelectFilter,
            pageType,
            productView: view,
            searchParams,
            setProductView,
            shopLink,
            sortBy
          }}
        />
      ) : null}
      {totalProductCount > 0 &&
      pageType !== 'AllBrandsPage' &&
      pageType !== 'AllSpecialsPage' ? (
        <div>
          <span
            className={
              'title-l font-regular text-primary'
            }>{`${totalProductCount} Results`}</span>
          {hasSelectedFilters() ? (
            <a
              role="button"
              tabIndex={0}
              className="margin-left-24 clear-filter body-sm underline pointer"
              onClick={() => {
                push(window.location.pathname);
                refresh();
              }}>
              Clear all filters
            </a>
          ) : null}
        </div>
      ) : null}
      <div className="d-flex overflow-hidden">
        {!isLoading &&
        products.length <= 0 &&
        !['AllBrandsPage', 'AllSpecialsPage'].includes(pageType) ? (
          <div className="ecom-product-text-block-wrapper">
            <TextBlock
              align="center"
              content={[
                `<h4 className="text-primary title-l">We couldn't find what you're looking for.</h4>
                  \n${pageType === 'SubSpecialPage' ? `See our other <u>[specials](${shopLink}/specials)</u>` : `Try a different ${pageType === 'SearchPage' ? 'search term' : 'filter'}`} or go back to our <u>[home page](${shopLink})</u>!`
              ]}
            />
          </div>
        ) : pageType === 'AllBrandsPage' ? (
          <div className="ecom-product-text-block-wrapper">
            <TextBlock
              align="center"
              content={[
                '<h2 class="h3 text-primary">Select a brand to get started!</h2>'
              ]}
            />
          </div>
        ) : pageType === 'AllSpecialsPage' ? (
          <div className="ecom-product-text-block-wrapper">
            <TextBlock
              align="center"
              content={[
                '<h2 class="h3 text-primary">Select a special to get started!</h2>'
              ]}
            />
          </div>
        ) : (
          <div className="ecom-grid-wrapper">
            <div
              className={`ecom-product-${view} ${totalProductCount <= 0 && !hasSelectedFilters() ? 'margin-top-32' : 'margin-top-5'}`}
              data-test="product-grid">
              {products.map((product, i) => (
                <ProductTile
                  key={`grid-${product.id}-${i}`}
                  isLoading={false}
                  product={product}
                  addItemToCart={addItemToCart}
                  shopLink={shopLink}
                  isMobile={isMobile}
                  view={view}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
