import { useRouter } from 'next/navigation';

import { ProductListTile, ProductListTilePopupProps } from '../ProductListTile';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { CTAButton } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Consolidate_ProductVariant } from 'services';

import CloseIcon from '../../assets/svgs/close/close-teal.svg';

import './product-popup.scss';

export type ProductPopupProps = {
  item: ProductListTilePopupProps['item'];
  variant: Consolidate_ProductVariant;
};

export const ProductPopup = ({ props }: { props: ProductPopupProps }) => {
  const { push } = useRouter();
  const { popup, selectedDispensary } = useSiteWideContext();

  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="product-popup">
        <div
          role="region"
          aria-live="polite"
          className="product-popup bg-white"
          data-test="product-popup">
          <div
            className="tail-container"
            aria-hidden="true"
            role="presentation">
            <div className="popup-tail bg-white" />
          </div>
          <div className="title bg-white d-flex align-items-center justify-content-between">
            <h5 className="body-xl text-primary margin-bottom-0">
              Added to Cart
            </h5>
            <button
              aria-label="close product popup"
              className="close-icon d-flex"
              onClick={() => {
                popup.setShow(false);
              }}>
              <CloseIcon aria-hidden="true" />
            </button>
          </div>
          <ProductListTile
            props={{
              ...props,
              isLoading: false,
              shopLink: selectedDispensary?.shopLink || '',
              type: 'popup'
            }}
          />
          <div className="cart-btn">
            <CTAButton
              fullWidth={true}
              text="View Cart"
              callBackFunc={() => {
                popup.setShow(false);
                push(`${selectedDispensary?.shopLink}/cart`);
              }}
              variant="primary"
            />
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
