import Link from 'next/link';

import { MobileCategoryTileProps } from '.';

export const MobileCategoryTile = ({
  link,
  title
}: MobileCategoryTileProps): JSX.Element | null => {
  return (
    <Link
      href={link}
      className="mobile-category-tile body-xl font-bold bg-tertiary-light text-center"
      data-test="category-tile">
      {title}
    </Link>
  );
};
