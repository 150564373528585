import {
  CS_CuraleafPage_Components,
  ContentStackService,
  calculateDecisionTree,
  formStatesNDispensariesMap,
  StoreCardDispensary
} from 'services';

import {
  articleGridMap,
  ctaDividerMap,
  faqBlockMap,
  heroBannerCarouselMap,
  heroBannerMap,
  imageBlockMap,
  instagramGridMap,
  instructionsBlockMap,
  multiImageTextBlockMap,
  productCardMap,
  promoCarouselMap,
  redirectMap,
  staticEmbeddableFormMap,
  textImageBlockCarouselMap,
  textImageBlockMap,
  videoBlockMap
} from './contentstackMap';
import { PageBuilderMap } from '../models/contentstackMap';

export const pageBuilderMap = async (
  component: CS_CuraleafPage_Components,
  stores?: StoreCardDispensary[] | null
): Promise<PageBuilderMap> => {
  const contentstack = new ContentStackService();

  try {
    switch (component.__typename) {
      case 'CuraleafPageModularBlocksArticleGrid': {
        return articleGridMap(component);
      }
      case 'CuraleafPageModularBlocksCustomComponent': {
        const type = component.custom_component.type;
        switch (type) {
          // case 'Florida Physicians Grid': {
          //   const physicians = await getFloridaPhysicians();

          //   return {
          //     __typename: component.__typename,
          //     brand,
          //     physicians,
          //     type: 'Florida Physicians Grid'
          //   };
          // }
          case 'careers': {
            const res = await contentstack.getCareerData(false);

            if (res.success) {
              return {
                __typename: component.__typename,
                careers: res.data,
                legal: {
                  text: 'California Consumer Privacy Act Notice',
                  link: '/california/cppa-job-applicant'
                },
                title: 'Current opportunities',
                type: 'careers',
                subtitle:
                  'If you are a cannabis advocate who is passionate about helping people feel their best, we openly invite you to explore our current career openings'
              };
            }
            console.warn('Error: Failed to fetch career data');
            return null;
          }
          case 'store-list': {
            if (stores?.length) {
              return {
                __typename: component.__typename,
                stores,
                type: 'store-list'
              };
            }
            console.warn(`Error: Missing stores for store-list component`);
            return null;
          }
          default: {
            console.warn(
              `Error: Missing page builder map for custom component: ${type}`
            );
            return null;
          }
        }
      }
      case 'CuraleafPageModularBlocksCtaDivider': {
        return ctaDividerMap(component);
      }
      case 'CuraleafPageModularBlocksDecisionTree': {
        const calculatedTree = await calculateDecisionTree(component);
        if (calculatedTree) {
          return {
            __typename: calculatedTree.__typename,
            anchor: calculatedTree.decision_tree.anchor,
            questions: calculatedTree.decision_tree.questions,
            startingHeader: calculatedTree.decision_tree.startingHeader
          };
        } else {
          console.warn('Error: Failed to fetch decision tree data');
          return null;
        }
      }
      case 'CuraleafPageModularBlocksEmbeddableForm': {
        const type =
          component.embeddable_form.formConnection.edges[0].node.type;
        const data = component.embeddable_form.formConnection.edges[0].node;

        const statesNdispensariesReq = await formStatesNDispensariesMap(
          data.dropdowntype,
          {
            states:
              component.embeddable_form.formConnection.edges[0].node
                .statesConnection.edges,
            dispensaries:
              component.embeddable_form.formConnection.edges[0].node
                .dispensariesConnection.edges
          }
        );

        const formConfigReq = await contentstack.getFormConfig(false);
        switch (type) {
          case 'Contact Us': {
            if (statesNdispensariesReq.success && formConfigReq.success) {
              return staticEmbeddableFormMap(
                component,
                formConfigReq.data,
                statesNdispensariesReq.data
              );
            }

            return null;
          }
          case 'Opt In': {
            if (statesNdispensariesReq.success && formConfigReq.success) {
              return staticEmbeddableFormMap(
                component,
                formConfigReq.data,
                statesNdispensariesReq.data
              );
            }

            return null;
          }
          default: {
            console.warn(
              `Error: Missing page builder map for embeddable form: ${type}`
            );
            return null;
          }
        }
      }
      case 'CuraleafPageModularBlocksFaqBlock': {
        return faqBlockMap(component);
      }
      case 'CuraleafPageModularBlocksHeroBanner': {
        return heroBannerMap(component);
      }
      case 'CuraleafPageModularBlocksHeroBannerCarousel': {
        return heroBannerCarouselMap(component);
      }
      case 'CuraleafPageModularBlocksIframe': {
        return {
          __typename: component.__typename,
          src: component.iframe.src
        };
      }
      case 'CuraleafPageModularBlocksImageBlock': {
        return imageBlockMap(component);
      }
      case 'CuraleafPageModularBlocksInstagramGrid': {
        return instagramGridMap(component);
      }
      case 'CuraleafPageModularBlocksInstructionsBlock': {
        return instructionsBlockMap(component);
      }
      case 'CuraleafPageModularBlocksMultiTextImageBlock': {
        return multiImageTextBlockMap(component);
      }
      case 'CuraleafPageModularBlocksProductCard': {
        return productCardMap(component);
      }
      case 'CuraleafPageModularBlocksProductListing': {
        return component;
      }
      case 'CuraleafPageModularBlocksPromoCarousel': {
        return promoCarouselMap(component);
      }
      case 'CuraleafPageModularBlocksRedirectSearch': {
        return redirectMap(component);
      }
      case 'CuraleafPageModularBlocksRewardsTable': {
        return {
          __typename: component.__typename,
          ...component.rewards_table
        };
      }
      case 'CuraleafPageModularBlocksTextImageBlock': {
        return textImageBlockMap(component);
      }
      case 'CuraleafPageModularBlocksTextImageBlockCarousel': {
        return textImageBlockCarouselMap(component);
      }
      case 'CuraleafPageModularBlocksVideoBlock': {
        return videoBlockMap(component);
      }
      default: {
        console.warn(
          `Error: Missing page builder map for component: ${JSON.stringify(
            component
          )}`
        );
        return null;
      }
    }
  } catch (err) {
    return null;
  }
};
