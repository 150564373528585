import classNames from 'classnames';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import { FloatLabel } from 'primereact/floatlabel';
import { Dropdown } from 'primereact/dropdown';

import {
  Consolidate_Product,
  Consolidate_ProductVariant,
  DatalayerAnalytics,
  ProductTileType,
  alphanumericSlugify,
  dutchieFriendlyName,
  slugify
} from 'services';

import { ProductImageCarousel } from './ProductImageCarousel';
import { EcomProductDescriptionProps } from './index';
import {
  ProductDetailsAccordion,
  ProductDetailsAccordionProps
} from './ProductDetailsAccordion';
import { Accordion } from '../Accordion';
import { BrandDescription } from './BrandDescription';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ecommerceSanitizeQuery } from '../../utils/url';
import { PriceSection } from '../PriceSection';
import { Markdown } from '../Markdown';
import { QuantitySelect } from '../QuantitySelect';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { PageState } from '../PageState';
import { CTAButton } from '../CTAButton';
import { StorefrontCarousel } from '../StorefrontCarousel';
import { productsAnalyticsMap } from '../../utils/storefrontCarouselsMap';
import { UNIT_MAP } from '../../utils/constants';
import { SwitchMenuDialog } from '../SwitchMenuDialog';
import { moodiDayScript } from '../../utils/moodiDayScript';
import {
  fetchProductsForRecommendations,
  RecommendationCarousel
} from '../../utils/getPdpRecs';

export type ProductSpecificationItemProps = {
  title: string;
  items: {
    key: string;
    value?: string | string[];
    hasRange?: boolean;
  }[];
};

type ProductSpecificationItem = 'detail' | 'profile' | 'terpene';

export type ProductSpecificationProps = {
  [key in ProductSpecificationItem]: ProductSpecificationItemProps;
};

const specificationsMap = (
  product: Consolidate_Product
): ProductSpecificationProps => {
  const {
    category,
    cannabinoids,
    effects,
    strainType,
    subcategory,
    potencyThc,
    potencyCbd,
    terpenes
  } = product;

  const mappedData: ProductSpecificationProps = {
    profile: {
      title: 'Strength Profile',
      items: []
    },
    terpene: {
      title: 'Terpenes',
      items: []
    },
    detail: {
      title: 'Specifications',
      items: []
    }
  };

  // SPECIFICATIONS
  if (strainType) {
    mappedData.detail.items.push({
      key: 'Lineage',
      value: dutchieFriendlyName(strainType)
    });
  }
  if (category && category !== 'NOT_APPLICABLE') {
    mappedData.detail.items.push({
      key: 'Category',
      value: dutchieFriendlyName(category)
    });
  }
  if (subcategory && subcategory !== 'DEFAULT') {
    //todo map in shop menu categories for CT extracts/concentrates
    mappedData.detail.items.push({
      key: 'Subcategory',
      value: dutchieFriendlyName(subcategory)
    });
  }
  if (effects?.length) {
    mappedData.detail.items;
    mappedData.detail.items.push({
      key: 'Effects',
      value: effects.map((effect) => {
        if (effect === effects[effects.length - 1]) {
          return effect.displayName;
        } else {
          return effect.displayName + ', ';
        }
      })
    });
  }

  // STRENGTH PROFILE
  if (potencyCbd?.formatted) {
    if (potencyCbd.range && potencyCbd.range.length > 1) {
      mappedData.profile.items.push({
        key: 'CBD',
        value: potencyCbd.formatted,
        hasRange: true
      });
    } else {
      mappedData.profile.items.push({
        key: 'CBD',
        value: potencyCbd.formatted
      });
    }
  }
  if (potencyThc?.formatted) {
    if (potencyThc.range && potencyThc.range.length > 1) {
      mappedData.profile.items.push({
        key: 'THC',
        value: potencyThc.formatted,
        hasRange: true
      });
    } else {
      mappedData.profile.items.push({
        key: 'THC',
        value: potencyThc.formatted
      });
    }
  }
  if (cannabinoids?.length) {
    const items = cannabinoids.reduce(
      (arr: ProductSpecificationProps['profile']['items'], canna) => {
        if (canna?.unit && canna?.cannabinoid?.name) {
          arr.push({
            key: canna.cannabinoid.name,
            value: `${canna.value}${UNIT_MAP[canna.unit]}`
          });
        }
        return arr;
      },
      []
    );
    mappedData.profile.items.push(...items);
  }

  // TERPENES
  if (terpenes?.length) {
    const items = terpenes.reduce(
      (arr: ProductSpecificationProps['terpene']['items'], terp) => {
        if (terp?.value && terp?.unitSymbol && terp?.terpene?.name) {
          arr.push({
            key: terp.terpene.name,
            value: `${terp.value}${terp.unitSymbol}`
          });
        }
        return arr;
      },
      []
    );
    mappedData.terpene.items.push(...items);
  }

  return mappedData;
};

export const ProductDescription = ({
  coaUrl,
  product
}: EcomProductDescriptionProps): JSX.Element | null => {
  const { addItemToCart, isMobile, userMenuType, websiteUrl, width } =
    useSiteWideContext();
  const { currentPathDispensary, defaultMenuType } = useDispensaryPathContext();

  const [selectedVariant, setSelectVariant] =
    useState<Consolidate_ProductVariant | null>(null);
  const [showMenuSwitchDialog, setMenuSwitchDialog] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] =
    useState<RecommendationCarousel>({
      all: [],
      category: []
    });

  const terpenesContent =
    product.terpenes && product.terpenes.length
      ? product.terpenes
          .filter((terp) => terp?.value && terp.value > 0)
          .reduce((arr: ProductDetailsAccordionProps['content'], terp) => {
            if (terp?.terpene) {
              arr.push({
                value: `${terp.terpene.name}: ${terp.value}${terp.unitSymbol}`,
                tooltip: terp.terpene.description
                  ? terp.terpene.description
                  : undefined
              });
            }
            return arr;
          }, [])
      : [];

  useEffect(() => {
    fetchProductsForRecommendations(
      websiteUrl,
      [product.id],
      currentPathDispensary.retailerId,
      defaultMenuType,
      setRecommendations,
      'ProductPage',
      currentPathDispensary.uid
    );
  }, []);

  const unitMap = (unit: string) => {
    switch (unit) {
      case 'MILLIGRAMS':
        return 'mg';
      case 'MILLIGRAMS_PER_GRAM':
        return 'mg/g';
      case 'MILLIGRAMS_PER_ML':
        return 'mg/ml';
      case 'PERCENTAGE':
        return '%';
    }
  };

  const highlightsContent = () => {
    const content = [];
    if (product.strainType && product.strainType !== 'NOT_APPLICABLE') {
      content.push({
        value: dutchieFriendlyName(product.strainType) // TODO: Check with HIGH_CBD etc
      });
    }
    const flowerEquivalent = product.variants[0]?.flowerEquivalent;
    if (
      flowerEquivalent &&
      !currentPathDispensary.storefrontMap?.hideFlowerEquivalent
    ) {
      content.push({
        value: `Flower Equivalent: ${flowerEquivalent.value}${flowerEquivalent.unit}`
      });
    }
    if (product?.potencyThc?.formatted) {
      const thcName = currentPathDispensary.shopLink.includes('curaleaf-ct-')
        ? 'Total THC'
        : 'THC';
      content.push({
        value: `${product.potencyThc.formatted} ${thcName}`
      });
    }
    if (product?.cannabinoids && product.cannabinoids.length) {
      product.cannabinoids.map((canna) => {
        if (!canna?.cannabinoid?.name || !canna.unit || !canna.value) return;

        content.push({
          value: `${canna.value}${unitMap(canna.unit)} ${
            canna.cannabinoid.name.split(' ')[0]
          }`,
          tooltip: canna.cannabinoid.description || ''
        });
      });
    }
    return content;
  };

  const hideEffects = process.env.HIDE_EFFECTS === 'true';
  const effectsContent =
    !hideEffects && product.effects
      ? product.effects.map((effect) => ({
          value: effect.displayName
        }))
      : [];

  const hasHighlights =
    highlightsContent().length ||
    terpenesContent.length ||
    (effectsContent && effectsContent.length);

  useEffect(() => {
    setQuantity(1);
  }, [selectedVariant, product]);

  useEffect(() => {
    if (product && !loading) {
      const specialVariant = product.variants.find(
        (variant) => variant.isSpecial && variant.specialPrice
      );
      setSelectVariant((specialVariant || product.variants[0]) ?? null);
    }
  }, [product]);

  useEffect(() => {
    if (!loading) {
      // MOODIDAY after products load in
      moodiDayScript();
    }
  }, [loading]);

  if (!selectedVariant) {
    return (
      <PageState
        header="Product not available."
        showPopularProducts={true}
        link={{
          url: currentPathDispensary.shopLink,
          text: 'Check out our other products!'
        }}
      />
    );
  }

  let buttonText = 'Add To Cart';
  let disableButton = false;
  let quantityMax = selectedVariant.quantity;
  if (currentPathDispensary?.pauseEcommerce) {
    buttonText = currentPathDispensary.pauseEcommerce;
    disableButton = true;
    quantityMax = 0;
  } else if (
    currentPathDispensary.menuTypes.length > 1 &&
    (!product.menuTypes.includes(userMenuType) ||
      !currentPathDispensary.menuTypes.includes(userMenuType))
  ) {
    buttonText = `${
      userMenuType === 'RECREATIONAL' ? 'Medical Only' : 'Adult-Use Only'
    }`;
    disableButton = true;
  }

  const oppsiteButtonText =
    userMenuType === 'MEDICAL' ? 'Adult-Use' : 'Medical';

  const onCoaClick = () => {
    const splitCoas = coaUrl!.split('/');
    DatalayerAnalytics.pushBasicEvent(
      'view_coa',
      {
        item_name: product.name, // name of the product being analyzed/described in the COA
        item_brand: product.brand?.name, // brand of analyzed item
        item_category: product.category, // category of item clicked
        file_name: splitCoas[splitCoas.length - 1], // the name of the file
        link_url: coaUrl // URL of the file
      },
      currentPathDispensary
    );
  };

  const productSpecs = specificationsMap(product);

  return (
    <>
      <SwitchMenuDialog
        show={showMenuSwitchDialog}
        setShow={setMenuSwitchDialog}
      />
      <div className="product-description d-flex gap-16 d-gap-24">
        <div className="right-col col-2">
          <div className="summary d-flex flex-column gap-16 d-gap-20">
            <div className="d-flex flex-column gap-8 d-gap-8">
              {product.brand ? (
                <Link
                  data-test="brand"
                  className="text-pastel-green underline body-xl"
                  href={ecommerceSanitizeQuery(
                    `${currentPathDispensary.shopLink}/brands/${alphanumericSlugify(product.brand.name)}`
                  )}>
                  {product.brand.name}
                </Link>
              ) : null}
              <h1 className="h3 text-primary margin-bottom-0">
                {product.name}
              </h1>
              <div
                // offset to center the moodi day rating
                className="moodi-day-rating-overlay margin-top-8"
                data-review-link="true"
                data-productid={product.id}></div>
            </div>
            <div className="pdp-divider"></div>
            <PriceSection
              isMobile={isMobile}
              variant={selectedVariant}
              type="pdp"
            />
            {product.variants.length > 1 ? (
              <div className="variant-dropdown">
                <FloatLabel>
                  <Dropdown
                    name="option"
                    value={{
                      label: selectedVariant.option,
                      variant: selectedVariant
                    }}
                    onChange={(e) => setSelectVariant(e.value.variant)}
                    options={product.variants.map((v) => ({
                      label: v.option,
                      variant: v
                    }))}
                    loading={loading}
                    optionLabel="label"
                    placeholder={
                      loading ? 'Loading...' : 'Other options available'
                    }
                  />
                  <label className="body-l" htmlFor="option">
                    Other options available
                  </label>
                </FloatLabel>
              </div>
            ) : selectedVariant.option !== 'N/A' ? (
              <div className="single-variant">
                <span className="body-l">{selectedVariant.option}</span>
              </div>
            ) : null}
            <div className="d-flex column-gap-16">
              <QuantitySelect
                type="pdp"
                value={quantity}
                max={quantityMax}
                onDecrement={async () => await setQuantity(quantity - 1)}
                onIncrement={async () => await setQuantity(quantity + 1)}
              />
              <div className="cart-button-col d-flex flex-column">
                <CTAButton
                  variant="secondary"
                  loading={loading}
                  data-test="cart-btn"
                  text={buttonText}
                  fullWidth={true}
                  size="lg"
                  disabled={disableButton}
                  callBackFunc={async () => {
                    if (addItemToCart) {
                      setLoading(true);
                      await addItemToCart(
                        {
                          product,
                          quantity,
                          variant: selectedVariant,
                          analyticsContext: {
                            list: {
                              id: 'pdp_page',
                              name: 'PDP Page'
                            }
                          },
                          baseCartUrl:
                            window.location.origin +
                            currentPathDispensary.shopLink
                        },
                        () => {
                          setLoading(false);
                        }
                      );
                    }
                  }}
                />
                {!product.menuTypes.includes(userMenuType) &&
                currentPathDispensary.menuTypes.length > 1 ? (
                  <div className="switch-menu-button text-right">
                    <a
                      role="button"
                      tabIndex={0}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          setMenuSwitchDialog(true);
                        }
                      }}
                      onClick={() => setMenuSwitchDialog(true)}
                      className="underline text-primary pointer">
                      Switch to {oppsiteButtonText} menu?
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            {hasHighlights && width && width > 1024 ? (
              <>
                <div className="pdp-divider"></div>
                <div className="highlights d-flex flex-column gap-10">
                  {highlightsContent().length && product.strainType ? (
                    <ProductDetailsAccordion
                      content={highlightsContent()}
                      icon={product.strainType.toLowerCase()}
                      variant="bullet"
                    />
                  ) : null}
                  {terpenesContent.length ? (
                    <ProductDetailsAccordion
                      content={terpenesContent}
                      icon="terpenes"
                      variant="comma"
                    />
                  ) : null}
                  {!hideEffects && effectsContent.length ? (
                    <ProductDetailsAccordion
                      content={effectsContent}
                      icon="effects"
                      variant="comma"
                    />
                  ) : null}
                </div>
              </>
            ) : null}
            {process.env.NEXT_PUBLIC_MOODIDAY_CLIENT_ID ? (
              <div id="moodi-day-widget" data-productid={product.id} />
            ) : null}
          </div>
        </div>
        <div className="left-col col-2">
          <ProductImageCarousel
            isLoading={false}
            productName={product.name}
            productImages={product.images}
            strainType={product.strainType}
            isTablet={!!(width && width <= 1024)}
          />
        </div>
      </div>
      <div className="pdp-lower gap-24 d-flex flex-column">
        {hasHighlights && width && width <= 1024 ? (
          <>
            <div className="pdp-divider"></div>
            <div className="highlights d-flex flex-column gap-10">
              {highlightsContent().length && product.strainType ? (
                <ProductDetailsAccordion
                  content={highlightsContent()}
                  icon={product.strainType.toLowerCase()}
                  variant="bullet"
                />
              ) : null}
              {terpenesContent.length ? (
                <ProductDetailsAccordion
                  content={terpenesContent}
                  icon="terpenes"
                  variant="comma"
                />
              ) : null}
              {!hideEffects && effectsContent.length ? (
                <ProductDetailsAccordion
                  content={effectsContent}
                  icon="effects"
                  variant="comma"
                />
              ) : null}
            </div>
            <div className="pdp-divider"></div>
          </>
        ) : null}
        {product.descriptionHtml ? (
          <Accordion
            mode="light"
            defaultOpen={true}
            variant="primary"
            header={
              <h2 className="title-l margin-bottom-0 text-primary">
                Product Details
              </h2>
            }
            content={
              <>
                {coaUrl ? (
                  <div className="d-flex justify-content-between align-items-center gap-8 margin-bottom-16">
                    <span className="body-l">Certificate of Analysis</span>
                    <a
                      onClick={onCoaClick}
                      className="body-l text-primary underline"
                      href={coaUrl}
                      target="_blank">
                      View Full Report
                    </a>
                  </div>
                ) : null}
                <Markdown
                  additionalClass="text-primary pdp-description"
                  content={product.descriptionHtml}
                />
              </>
            }
          />
        ) : null}
        {Object.entries(productSpecs).map((entry) => {
          if (entry[1].items.length) {
            return (
              <div id={entry[1].title.toLowerCase()} key={entry[1].title}>
                <Accordion
                  key={entry[1].title}
                  mode="light"
                  variant="primary"
                  defaultOpen={!isMobile}
                  header={
                    <h2 className="title-l margin-bottom-0 text-primary">
                      {entry[1].title}
                    </h2>
                  }
                  content={
                    <ul className="product-spec-list">
                      {entry[1].items.map((p) => {
                        return (
                          <li className="d-flex" key={p.key}>
                            <span
                              className={classNames(`body-l text-primary`, {
                                uppercase: entry[1].title === 'Specifications'
                              })}>
                              {p.key}
                            </span>
                            <span className="body-l text-primary">
                              {p.value}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  }
                />
              </div>
            );
          }
        })}
        {product.brand?.description.length ? (
          <BrandDescription
            props={{
              isLoading: false,
              productBrand: product.brand
            }}
          />
        ) : null}
        {product && recommendations && Object.keys(recommendations).length
          ? Object.keys(recommendations).map((key, i) => {
              const recommendation: ProductTileType[] =
                recommendations[key as keyof typeof recommendations];
              if (recommendation.length >= 4) {
                return (
                  <StorefrontCarousel
                    key={`${product.category}-${i}`}
                    banners={productsAnalyticsMap(
                      addItemToCart,
                      recommendation
                    )}
                    isLoading={false}
                    link={
                      key === 'category'
                        ? `${
                            currentPathDispensary.shopLink
                          }/categories/${slugify(product.category)}`
                        : ''
                    }
                    title={
                      key === 'category'
                        ? `More ${dutchieFriendlyName(product.category)}`
                        : 'Similar Products'
                    }
                    type="products"
                  />
                );
              }
            })
          : null}
      </div>
      <div className="margin-bottom-24">
        <small>
          * Curaleaf prices and promotions are subject to change without
          notice.Promotions may be limited by the availability of product.
          Occasionally, our prices and promotions may contain typographical
          errors, inaccuracies, or omissions.We reserve the right to correct any
          errors, inaccuracies or omissions, and to change or update information
          or cancel orders if any information is incorrect at any time without
          prior notice. This includes the right to cancel your order after it
          has been submitted.
        </small>
      </div>
    </>
  );
};
