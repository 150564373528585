import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import {
  AuthStatus,
  CreateAccountData,
  DatalayerAnalytics,
  registerUser
} from 'services';
import { CTAButton } from '../../../CTAButton';
import { FormStatus } from '../../../FormBuilder/Account/status';
import { CheckboxSelection } from '../../../FormBuilder/components/Checkbox';
import { PasswordField } from '../../../FormBuilder/components/Password/password';
import { DateField } from '../../../FormBuilder/components/Date';
import { TextInput } from '../../../FormBuilder/components/TextInput';
import { DropdownSelection } from '../../../FormBuilder/components/DropdownSelection';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { AccountConfirmationForm } from './confirmation';
import { useSiteWideContext } from '../../../../hooks/siteWideContext';
import { StateToAbbrArray as states } from '../../../../utils/states';
import { validationErrorCheck } from '../../../../utils/forms';
import {
  isStringValid,
  isPhoneValid,
  isEmailValid,
  isPasswordValid
} from '../../../../utils/validators';

export type CreateAccountProps = {
  header: string;
  referralToken?: string;
};

export const CreateAccountForm = ({
  header,
  referralToken
}: CreateAccountProps) => {
  const {
    selectedDispensary,
    setAccountDrawerMode,
    setAccountDrawerVisible,
    websiteUrl
  } = useSiteWideContext();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [error, setError] = useState<AuthStatus>({
    text: ''
  });
  const [lockEmail, setLockEmail] = useState(false);
  const matchingState = selectedDispensary
    ? states.find(
        (state) =>
          state.abbreviation === selectedDispensary.location.state.abbreviation
      )
    : undefined;
  const state = matchingState?.name || '';

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthday: '',
      state,
      password: '',
      confirmPassword: '',
      optIn: true,
      optInSms: true
    },
    validateOnChange: false,
    validate: async (data: CreateAccountData) => {
      const ageLimit = moment().subtract(18, 'years');
      const errors = {
        firstName: !data.firstName
          ? 'Please enter your first name.'
          : !isStringValid(data.firstName, 2, 32)
            ? 'Invalid first name.'
            : '',
        lastName: !data.lastName
          ? 'Please enter your last name.'
          : !isStringValid(data.lastName, 2, 32)
            ? 'Invalid last name.'
            : '',
        phone: !data.phone
          ? 'Please enter a valid phone number.'
          : !(await isPhoneValid(data.phone, websiteUrl))
            ? 'Invalid phone number.'
            : '',
        email: !data.email
          ? 'Please enter a valid email address.'
          : !isEmailValid(data.email)
            ? 'Invalid email address (e.g. example@email.com).'
            : '',
        birthday: !data.birthday
          ? 'Please enter your birthday.'
          : moment(data.birthday).isAfter(ageLimit)
            ? 'You must be of legal age to shop'
            : '',
        state: !data.state ? 'Please select your state.' : '',
        password: !data.password
          ? 'Please enter a password.'
          : !isPasswordValid(data.password)
            ? 'Please enter a stronger password.'
            : '',
        confirmPassword: !data.confirmPassword
          ? 'Please reenter your password.'
          : ''
      };

      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        errors.password = ' ';
        errors.confirmPassword = 'Passwords do not match.';
      }

      return validationErrorCheck(errors);
    },
    onSubmit: async (values: CreateAccountData) => {
      setLoading(true);
      const submit: boolean | string = await registerUser({
        ...values,
        referralToken,
        setError,
        setVisibility: setAccountDrawerVisible
      });
      setLoading(false);
      formik.resetForm();

      if (!submit) {
        formik.setValues({
          ...values,
          password: '',
          confirmPassword: ''
        });
      } else {
        DatalayerAnalytics.pushAccountEvent({
          action: 'create_account'
        });
        setConfirmation(true);
        formik.setFieldValue('email', values.email);
      }
    }
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    if (email && isEmailValid(email)) {
      setLockEmail(true);
      formik.setFieldValue('email', email);
    }
  }, []);

  if (showConfirmation && formik.values.email) {
    // Markdown is converting this into an anchor
    const email = formik.values.email.replace('@', '&#64;');
    return (
      <AccountConfirmationForm
        title="Verify Your Email"
        content={`We sent an email to **${email}** with a link to verify your email.`}
      />
    );
  }

  return (
    <PackageComponentWrapper>
      <form className="account-form" onSubmit={formik.handleSubmit}>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">{header}</h2>
          {error.text ? <FormStatus {...error} type="error" /> : null}
          <div className="text-primary">
            <span>Already have an account? </span>
            <a
              tabIndex={0}
              className="hover-underline pointer text-specialty underline"
              onClick={() => setAccountDrawerMode('login')}>
              Sign In
            </a>
          </div>
          {referralToken ? (
            <>
              <div className="body-l row bg-success-light text-primary margin-top-16 margin-bottom-16 padding-16 sm-border-radius">
                <span className="subtitle">
                  <strong>Best Buds</strong> <br />
                  Using referral code {referralToken}
                </span>
              </div>
              <small>
                Using accurate information ensures that any points you earn from
                future purchases will be properly attributed to your account.{' '}
                <strong>Account sharing is not allowed.</strong>
              </small>
            </>
          ) : (
            <div className="text-primary margin-top-16">
              <small>
                Please use the same email, first name, and last name as you did
                for your previous purchases or accounts. This will help keep
                your rewards balance intact.{' '}
                <strong>Account sharing is not allowed.</strong>
              </small>
            </div>
          )}
        </div>
        <div className="sidebar-form-padded">
          <TextInput
            title="First Name"
            type="name"
            field="firstName"
            formik={formik}
            required={true}
            customId="first-name"
            autoComplete="given-name"
          />
          <TextInput
            title="Last Name"
            type="name"
            field="lastName"
            formik={formik}
            required={true}
            customId="last-name"
            autoComplete="family-name"
            disclaimer="Enter your full name as it appears on your ID"
          />
          <TextInput
            title="Email"
            type="email"
            field="email"
            formik={formik}
            required={true}
            disabled={lockEmail}
            autoComplete="email"
          />
          <TextInput
            title="Phone Number"
            type="tel"
            field="phone"
            formik={formik}
            required={true}
            autoComplete="tel"
          />
          <DateField
            title="Birthday"
            field="birthday"
            formik={formik}
            required={true}
          />
          <DropdownSelection
            title="State"
            dropdown_options={states.map((state) => state.name)}
            formik={formik}
            field="state"
            required={true}
          />
          <PasswordField
            field="password"
            formik={formik}
            title="Password"
            required={true}
            showFeedback={true}
          />
          <PasswordField
            field="confirmPassword"
            formik={formik}
            title="Confirm Password"
            required={true}
          />
          <div className="optin-checkbox-container">
            <span className="optin-checkbox-label text-primary">
              I want to receive offers, rewards, and other marketing
              communications right to my phone:
            </span>
            <CheckboxSelection
              checkbox_options={{
                label: 'SMS'
              }}
              field="optInSms"
              formik={formik}
            />
          </div>
          <CTAButton
            disabled={!formik.dirty}
            loading={loading}
            text="Create Account"
            variant="primary"
            type="submit"
            fullWidth={true}
          />
          <div className="text-primary margin-top-16">
            <small>
              By creating an account, you are enrolling into the Curaleaf
              Rewards program and opting into email/SMS marketing messages.
              Unsubscribe at any time in our preference center or the
              unsubscribe link in any email. Text &quot;Stop&quot; to
              unsubscribe from SMS.
            </small>
          </div>
          <div className="text-primary margin-top-16">
            <small>
              By creating an account, you agree to our&nbsp;
              <span>
                <a
                  className="underline body-sm text-primary"
                  href="/terms-of-service">
                  Terms of Use
                </a>
              </span>
              &nbsp;and&nbsp;
              <span>
                <a
                  className="underline body-sm text-primary"
                  href="/privacy-policy">
                  Privacy Policy.
                </a>
              </span>
            </small>
          </div>
        </div>
      </form>
    </PackageComponentWrapper>
  );
};
