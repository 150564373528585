import {
  DutchiePlus_Generated_Product_Brand,
  capitalize,
  dutchieFriendlyName
} from 'services';
import { SpecialTileProps } from '../components/StorefrontCarousel/components/SpecialTile';
import { PGPProps } from '../templates/newProductGridPage';
import { EcomProductPageType } from '../models/productPage';

export const getPageType = (
  asPath: string,
  searchParams: PGPProps['searchParams'],
  options?: {
    offer?: SpecialTileProps;
    brand?: DutchiePlus_Generated_Product_Brand | null;
  }
): EcomProductPageType => {
  const pathSegments = asPath.toLowerCase().split('/');
  const value = pathSegments[pathSegments.length - 1]?.split('?')[0] || '';

  // ALL BRANDS & SUB BRAND PAGE
  if (pathSegments.includes('brands') || pathSegments.includes('brands2')) {
    if (value !== 'brands' && value !== 'brands2') {
      if (options?.brand) {
        return {
          type: 'SubBrandPage',
          pageTitle: options.brand.name,
          brandKey: value,
          seoTitle: options.brand.name
        };
      }
      return {
        type: 'SubBrandPage',
        pageTitle: capitalize(value),
        brandKey: value,
        seoTitle: capitalize(value)
      };
    }
    return {
      type: 'AllBrandsPage',
      pageTitle: 'Brands',
      seoTitle: 'Brands'
    };
  }
  // CATEGORY SUB PAGE
  if (
    pathSegments.includes('categories') ||
    pathSegments.includes('categories2')
  ) {
    const title = value;
    return {
      type: 'SubCategoryPage',
      pageTitle: dutchieFriendlyName(title),
      categoryKey: value,
      seoTitle: `Cannabis ${title}`
    };
  }
  // ALL SPECIALS & SUB SPECIAL PAGE
  if (
    pathSegments.includes('specials') ||
    value === 'specials' ||
    pathSegments.includes('specials2')
  ) {
    return options?.offer
      ? {
          type: 'SubSpecialPage',
          pageTitle: `${options.offer.title}`,
          seoTitle: capitalize(options.offer.title)
        }
      : {
          type:
            pathSegments.includes('specials') && value !== 'specials'
              ? 'SubSpecialPage'
              : 'AllSpecialsPage',
          pageTitle: 'Specials',
          seoTitle: 'Specials'
        };
  }
  if (searchParams?.k) {
    return {
      type: 'SearchPage',
      pageTitle: searchParams.k,
      seoTitle: searchParams.k
    };
  }
  // ALL
  return {
    type: 'AllProductsPage',
    pageTitle: 'All Products',
    seoTitle: 'Products'
  };
};
