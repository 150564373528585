import { SocialMediaLinksData } from '../../models/types';

import FacebookIcon from '../../assets/svgs/social/facebook.svg';
import InstagramIcon from '../../assets/svgs/social/instagram.svg';
import LinkedInIcon from '../../assets/svgs/social/linkedin.svg';
import TwitterIcon from '../../assets/svgs/social/x.svg';
import YoutubeIcon from '../../assets/svgs/social/youtube.svg';

import './social-icons.scss';

export type SocialIconsProps = {
  overrideClass?: string;
  socialMediaLinks: SocialMediaLinksData[];
  size?: 'normal' | 'small';
  variant?: 'primary' | 'secondary';
};

export const SocialIcons = ({
  overrideClass,
  socialMediaLinks,
  size = 'normal',
  variant = 'primary'
}: SocialIconsProps): JSX.Element | null => {
  if (!socialMediaLinks || !socialMediaLinks.length) return null;
  const getIcon = (corporateType: string) => {
    switch (corporateType) {
      case 'Facebook':
        return <FacebookIcon />;
      case 'Instagram':
        return <InstagramIcon />;
      case 'LinkedIn':
        return <LinkedInIcon />;
      case 'Twitter/X':
      case 'Twitter':
        return <TwitterIcon />;
      case 'YouTube':
        return <YoutubeIcon />;
      default:
        console.warn('Social icon not found!');
        return null;
    }
  };

  return (
    <div className="social-icons-curaleaf">
      <div
        data-test="social-data"
        className={overrideClass || 'd-flex justify-content-between'}>
        {socialMediaLinks.map((socialMediaLinks: SocialMediaLinksData) => (
          <div
            className="s-icon"
            key={socialMediaLinks.id}
            data-test="social-icon">
            <a
              className={`${variant} ${size}`}
              aria-label="social icon"
              href={socialMediaLinks.url}
              target="_blank">
              {getIcon(socialMediaLinks.corporate_type)}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
