'use client';

import { useRef } from 'react';
import { Menu } from 'primereact/menu';

import { HeaderLink } from '../../HeaderNavigation';

import './sublink-dropdown.scss';

export type SublinkDropdownProps = {
  navLink: HeaderLink[number];
};

export const SublinkDropdown = ({
  navLink
}: SublinkDropdownProps): JSX.Element | null => {
  const menuRef = useRef(null);

  return (
    <>
      <Menu
        model={[
          {
            items: navLink.sublinks?.map((link) => ({
              label: link.title
            }))
          }
        ]}
        className="sublink-dropdown"
        popup
        ref={menuRef}
        id="popup_menu_left"
      />
      <a
        role="button"
        onClick={(event) => (menuRef.current as any).toggle(event)}
        aria-controls="popup_menu_left"
        aria-haspopup
        className="body-xl text-tertiary-primary pointer">
        {navLink.title}
      </a>
    </>
  );
};
