import { Checkbox } from 'primereact/checkbox';
import { DatalayerAnalytics, dutchieFriendlyName } from 'services';
import { FilterAccordionProps } from '../index';
import { FilterKeys, FilterMenuItem } from '../../index';
import { useSiteWideContext } from '../../../../../hooks/siteWideContext';

import CloseIcon from '../../../../../assets/svgs/close/close-teal-circle.svg';

export const FilterCheckboxItem = ({
  item,
  onSelect,
  header
}: {
  item: FilterMenuItem;
  onSelect: FilterAccordionProps['onSelect'];
  header: FilterKeys;
}) => {
  const { width } = useSiteWideContext();
  const onCheckBoxSelect = (header: FilterKeys, item: FilterMenuItem) => {
    try {
      DatalayerAnalytics.pushFilterInteraction({
        action: item.isSelected ? 'remove' : 'add',
        filter_type: 'checkbox'
      });
    } catch (err) {
      console.error(err);
    }
    onSelect(header, item);
  };

  if (width && width >= 1024) {
    return (
      <div className="filter-item d-flex flex-row">
        <Checkbox
          tabIndex={0}
          data-test="checkbox"
          value={item.label}
          checked={item.isSelected}
          onChange={() => onCheckBoxSelect(header, item)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onCheckBoxSelect(header, item);
          }}
        />
        <label data-ignore-color="true">
          {header !== 'Brands' && header !== 'Options'
            ? dutchieFriendlyName(item.label)
            : // some logic to make apparel items uppercase
              // fortunately casing doesnt matter here
              header === 'Options' &&
                (item.label.includes('x') || item.label.length === 1)
              ? item.label.toUpperCase()
              : item.label}
        </label>
      </div>
    );
  } else {
    return (
      <a
        tabIndex={0}
        type="button"
        data-selected={item.isSelected}
        className="mobile-filter-tag d-flex pointer bg-white sm-border-radius body-m"
        onClick={() => onCheckBoxSelect(header, item)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onCheckBoxSelect(header, item);
        }}>
        {dutchieFriendlyName(item.label)}
        {item.isSelected ? <CloseIcon /> : null}
      </a>
    );
  }
};
