import { LoadingSkeleton } from '../Loader/Skeleton';
import { OrderSummary } from '../OrderSummary';
import { ProductListTile } from '../ProductListTile';

import CuraleafLogo from '../../assets/svgs/header/full-logo-teal.svg';
import { CTAButton } from '../CTAButton';

export const Loader = (): JSX.Element | null => {
  const cartTiles = new Array(3).fill(undefined);

  return (
    <div className="cart-summary d-flex flex-column">
      <h2 className="header h3 margin-bottom-0 text-primary">Cart</h2>
      <div className="summary-container d-flex">
        <div className="tiles d-flex flex-column">
          {cartTiles.map((_, i) => (
            <ProductListTile
              key={i}
              props={{
                isLoading: true
              }}
            />
          ))}
        </div>
        <div className="order-summary-wrapper d-flex flex-column">
          <div className="order-summary bg-secondary-light padding-32 d-flex flex-column justify-content-between gap-32">
            <div data-logged-in={false} className="d-flex flex-column gap-16">
              <CuraleafLogo />
              <LoadingSkeleton />
              <div className="hairline" />
              <OrderSummary
                props={{
                  isLoading: true
                }}
              />
            </div>
            <CTAButton loading={true} text="" variant="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};
