import Link from 'next/link';

import { ecommerceSanitizeQuery } from '../../../utils/url';
import { SpecialTileProps } from '../../StorefrontCarousel/components/SpecialTile';

import TagIcon from '../../../assets/svgs/specials/icon-specials-tag.svg';

import './special-offers-tag.scss';

type SpecialOffersTagProps = {
  productSpecials: SpecialTileProps[];
  shopLink: string;
};

export const SpecialOffersTag = ({
  productSpecials,
  shopLink
}: SpecialOffersTagProps): JSX.Element | null => {
  return (
    <div className="special-offers-tag">
      <div className="border-wrapper d-flex padding-20">
        <div className="specials-tag-icon">
          <TagIcon />
        </div>
        <div className="offer-content">
          <h2 className="body-l font-bold margin-bottom-5">Special Offers</h2>
          <ul
            className={
              productSpecials.length > 1
                ? 'special-list'
                : 'special-list-single'
            }>
            {productSpecials.map((s) => (
              <li key={s.title}>
                <Link
                  className="body-m text-decoration-none hover-underline"
                  href={ecommerceSanitizeQuery(`${shopLink}/specials/${s.id}`)}>
                  {s.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
