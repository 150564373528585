import { Accordion } from '../Accordion';
import { LoadingSkeleton } from '../Loader/Skeleton';
import { ProductImageCarousel } from './ProductImageCarousel';

export const Loader = (): JSX.Element | null => {
  return (
    <>
      <div className="product-description d-flex gap-16 d-gap-24">
        <div className="right-col col-2">
          <div className="summary d-flex flex-column gap-16 d-gap-20">
            <div className="d-flex flex-column gap-8 d-gap-8">
              <a
                data-test="brand"
                className="text-pastel-green underline body-xl">
                <LoadingSkeleton width="70px" />
              </a>
              <h1 className="h3 text-primary margin-bottom-0">
                <LoadingSkeleton width="70%" />
              </h1>
              <div className="margin-top-8"></div>
            </div>
            <div className="pdp-divider"></div>
            <div className="d-flex flex-column">
              <span className="text-black h1">
                <LoadingSkeleton width="70px" />
              </span>
              <span className="title-m font-bold strikethrough text-error-dark margin-top-3">
                <LoadingSkeleton width="80px" />
              </span>
              <span className="title-m font-regular text-success-primary margin-top-3">
                <LoadingSkeleton width="80px" />
              </span>
            </div>
            <div className="d-flex column-gap-16">
              <LoadingSkeleton width="123px" height="56px" />
              <LoadingSkeleton width="100%" height="56px" />
            </div>
          </div>
        </div>
        <div className="left-col col-2">
          <ProductImageCarousel isLoading={true} />
        </div>
      </div>
      <div className="pdp-lower d-flex flex-column">
        <div className="mobile-container">
          <Accordion
            additionalClass="margin-top-32"
            mode="light"
            defaultOpen={true}
            variant="primary"
            header={
              <h2 className="title-l margin-bottom-0 text-primary">
                <LoadingSkeleton width="200px" />
              </h2>
            }
            content={
              <div>
                <p className="body-l text-primary pdp-description">
                  <LoadingSkeleton width="100%" />
                </p>
                <p className="body-l text-primary pdp-description">
                  <LoadingSkeleton width="100%" />
                </p>
              </div>
            }
          />
          <Accordion
            additionalClass="margin-top-32"
            mode="light"
            defaultOpen={true}
            variant="primary"
            header={
              <h2 className="title-l margin-bottom-0 text-primary">
                <LoadingSkeleton width="200px" />
              </h2>
            }
            content={
              <div>
                <p className="body-l text-primary pdp-description">
                  <LoadingSkeleton width="100%" />
                </p>
                <p className="body-l text-primary pdp-description">
                  <LoadingSkeleton width="100%" />
                </p>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
