import classNames from 'classnames';
import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ecommerceSanitizeQuery } from '../../utils/url';

import UserIcon from '../../assets/svgs/header/user.svg';
import LoggedInUserIcon from '../../assets/svgs/header/user-filled.svg';
import CartIcon from '../../assets/svgs/header/cart.svg';
import SmUserIcon from '../../assets/svgs/header/user-sm.svg';
import SmLoggedInUserIcon from '../../assets/svgs/header/user-filled-sm.svg';
import SmCartIcon from '../../assets/svgs/header/cart-sm.svg';
import SearchIcon from '../../assets/svgs/header/search-teal.svg';
import Hamburger from '../../assets/svgs/header/hamburger.svg';

export type UserCartProps = {
  hamburger?: {
    navOpen: boolean;
    toggleNav: (val: boolean) => void;
  };
  search?: {
    openSearch: boolean;
    setOpenSearch: Dispatch<SetStateAction<boolean>>;
  };
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
};

export const UserCart = ({
  hamburger,
  search,
  setShowTooltip
}: UserCartProps): JSX.Element | null => {
  const { isLoggedIn, isTablet, selectedDispensary, quantity } =
    useSiteWideContext();

  const renderUserIcon = () => {
    if (isTablet) {
      if (isLoggedIn) return <SmLoggedInUserIcon />;
      return <SmUserIcon />;
    } else {
      if (isLoggedIn) return <LoggedInUserIcon />;
      return <UserIcon />;
    }
  };

  return (
    <div
      data-has-hamburger={!!hamburger}
      className={classNames('user-cart d-flex', {
        'align-items-center': !isTablet || hamburger,
        'justify-content-end': isTablet && !hamburger
      })}>
      {search ? (
        <div className="pointer">
          <div
            tabIndex={0}
            onClick={() => search.setOpenSearch(!search.openSearch)}
            onKeyDown={(event) =>
              event.key === 'Enter' && search.setOpenSearch(!search.openSearch)
            }>
            <SearchIcon />
          </div>
        </div>
      ) : null}
      <div className="pointer">
        <div
          tabIndex={0}
          onClick={() => setShowTooltip(true)}
          onKeyDown={(event) => event.key === 'Enter' && setShowTooltip(true)}>
          {renderUserIcon()}
        </div>
      </div>
      {selectedDispensary ? (
        <Link
          tabIndex={0}
          href={ecommerceSanitizeQuery(`${selectedDispensary.shopLink}/cart`)}
          className="icon-link">
          {isTablet ? <SmCartIcon /> : <CartIcon />}
          {quantity > 0 ? (
            <span className="quantity d-flex align-items-center justify-content-center body-sm text-white bg-primary">
              {quantity}
            </span>
          ) : null}
        </Link>
      ) : null}
      {hamburger ? (
        <div
          role="navigation"
          tabIndex={0}
          className="mobile-nav-toggle pointer padding-0"
          onClick={() => hamburger.toggleNav(true)}
          onKeyDown={(event) =>
            event.key === 'Enter' && hamburger.toggleNav(true)
          }>
          <Hamburger
            aria-controls="navigation"
            aria-expanded={hamburger.navOpen}
          />
        </div>
      ) : null}
    </div>
  );
};
