import { Loader } from './loader';
import { EcomProductGridProps, ProductViewType } from '../index';
import { FilterMenus } from '../ProductFilter';
import { ProductSortFilter as Default } from './default';
import { PGPProps } from '../../../templates/oldProductGridPage';
import { SortBy } from '../../../models/types';
import { EcomProductPageType } from '../../../models/productPage';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './product-sort-filter.scss';

export type ProductSortFilterProps = {
  filters: FilterMenus;
  hideFilter?: boolean;
  isLoading: false;
  onSelectFilter: EcomProductGridProps['onSelectFilter'];
  pageTitle: string;
  pageType: EcomProductPageType['type'];
  productView: ProductViewType;
  productLength: number;
  searchParams: PGPProps['searchParams'];
  setProductView: (productView: ProductViewType) => void;
  shopLink: string;
  sortBy: SortBy;
};

export type LoadingProps = {
  isLoading: true;
  pageTitle: string;
};

export const ProductSortFilter = ({
  props
}: {
  props: ProductSortFilterProps | LoadingProps;
}): JSX.Element | null => {
  if (props.isLoading) {
    return <Loader {...props} />;
  } else {
    if (!props.filters) return null;
    return (
      <ErrorBoundary component="product-sort-filter">
        <Default {...props} />
      </ErrorBoundary>
    );
  }
};
