import Image from 'next/image';

import { useCarousel } from '../../../hooks/useCarousel';
import Carousel from '../../Carousel';
import { CarouselDots, CarouselThumbnails } from '../../Carousel/components';
import { StrainTag } from '../../ProductTile/StrainTag';
import { ProductImageCarouselProps } from '.';

import ProductDefaultImage from '../../../assets/images/product-placeholder.png';

export const ProductImageCarousel = ({
  isTablet,
  productImages,
  productName,
  strainType
}: ProductImageCarouselProps) => {
  let mappedImages = productImages.map((img, i) => ({
    url: img.url,
    id: i
  }));

  if (!productImages.length) {
    mappedImages = [
      {
        id: 0,
        url: ProductDefaultImage
      }
    ];
  }

  const {
    activeImageIndex,
    autoSlide,
    autoSlideSeconds,
    selectedItem,
    setActiveImageIndex,
    setAutoSlide,
    setClassName,
    setSelectedItem
  } = useCarousel(mappedImages, false, 7);

  const states = {
    activeImageIndex,
    autoSlide,
    autoSlideSeconds,
    selectedItem,
    setActiveImageIndex,
    setAutoSlide,
    setClassName,
    setSelectedItem
  };

  return (
    <div className="pdp-image-carousel d-flex gap-24">
      {!isTablet && mappedImages.length > 1 ? (
        <div className="storefront-banner-dots thumbs">
          <CarouselThumbnails data={mappedImages} states={states} />
        </div>
      ) : mappedImages.length > 1 ? (
        <div className="storefront-banner-dots thumbs">
          <CarouselDots dotName="sm-dot" data={mappedImages} states={states} />
        </div>
      ) : null}
      <div className="carousel-wrapper">
        <Carousel data={mappedImages} states={states}>
          {mappedImages
            .filter((img) => img)
            .map((img, i) => {
              return (
                <div
                  key={img.url}
                  className="carousel-el"
                  style={{
                    transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
                  }}>
                  <div>
                    <div className="product-image-el">
                      <StrainTag strain={strainType} />
                      <Image
                        src={img.url}
                        alt={productName}
                        priority={i === 0}
                        loading={i === 0 ? 'eager' : 'lazy'}
                        fill={true}
                        sizes="100%"
                        style={{
                          objectFit: 'contain',
                          objectPosition: 'center'
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};
