import { Loader } from './loader';
import { SummaryBlock as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

export enum ValueType {
  Chip = 'chip',
  Price = 'price',
  Discount = 'discount'
}

export enum FontOption {
  Bold = 'bold'
}

export type SummaryBlockProps = {
  isLoading: false;
  info: {
    keyName?: string;
    value?: string | null;
    keyOptions?: {
      tooltip?: string;
    };
    valueOptions?: {
      type?: ValueType;
    };
    lineOptions?: {
      separatorAbove?: boolean;
      indent?: boolean;
      font?: FontOption;
      skip?: boolean;
    };
  }[];
  stateFeeDisclaimer?: boolean;
};

export type LoadingProps = {
  isLoading: true;
};

export const SummaryBlock = ({
  props
}: {
  props: SummaryBlockProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="summary-block">
      <Default {...props} />
    </ErrorBoundary>
  );
