'use client';

import { MainHeaderNavigation } from './main';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

export type HeaderLink = {
  title: string;
  link?: string;
  pixelLength?: number;
  sublinks?: Omit<HeaderLink, 'sublinks'> & {
    sublinks?: { title: string; link: string };
  };
}[];

export type MainHeaderNavigationProps = {
  navLinks: HeaderLink;
  navOpen: boolean;
  toggleNav: (val: boolean) => void;
  type: 'main';
};

export type EcomHeaderNavigationProps = {
  type: 'ecom';
};

export type KioskHeaderNavigationProps = {
  type: 'kiosk';
};

export const HeaderNavigationNew = (
  props:
    | MainHeaderNavigationProps
    | EcomHeaderNavigationProps
    | KioskHeaderNavigationProps
): JSX.Element | null => {
  if (props.type === 'main') {
    return (
      <ErrorBoundary component="header">
        <MainHeaderNavigation {...props} />
      </ErrorBoundary>
    );
  }
  return null;
};
