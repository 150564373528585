import { Checkbox } from 'primereact/checkbox';

import { DatalayerAnalytics, dutchieFriendlyName } from 'services';
import { FilterAccordionProps } from '../index';
import { FilterKeys, FilterMenuItem } from '../../index';

import Check from '../../../../../../assets/svgs/shared/check.svg';

export const FilterCheckboxItem = ({
  item,
  onSelect,
  header
}: {
  item: FilterMenuItem;
  onSelect: FilterAccordionProps['onSelect'];
  header: FilterKeys;
}) => {
  const onCheckBoxSelect = (header: FilterKeys, item: FilterMenuItem) => {
    try {
      DatalayerAnalytics.pushFilterInteraction({
        action: item.isSelected ? 'remove' : 'add',
        filter_type: 'checkbox'
      });
    } catch (err) {
      console.error(err);
    }
    onSelect(header, item);
  };

  return (
    <div className="filter-item-checkbox d-flex flex-row align-items-center">
      <Checkbox
        inputId={item.label}
        icon={<Check />}
        tabIndex={0}
        data-test="checkbox"
        value={item.label}
        checked={item.isSelected}
        onChange={() => onCheckBoxSelect(header, item)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onCheckBoxSelect(header, item);
          }
        }}
      />
      <label htmlFor={item.label} className="pointer">
        {header !== 'Brands' && header !== 'Options'
          ? dutchieFriendlyName(item.label)
          : // some logic to make apparel items uppercase
            // fortunately casing doesnt matter here
            header === 'Options' &&
              (item.label.includes('x') || item.label.length === 1)
            ? item.label.toUpperCase()
            : item.label}
      </label>
    </div>
  );
};
