'use client';

import { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';

import { MainHeaderNavigationProps } from '..';
import { SiteWideWrapperProps } from '../../../hooks/siteWideContext';

import CloseIcon from '../../../assets/svgs/close/close-drawer.svg';
import Logo from '../../../assets/svgs/header/full-logo-teal-ecom-footer.svg';
import BackArrow from '../../../assets/svgs/arrows/shop-carousel-left-arrow.svg';

import './drawer.scss';

export type HeaderDrawerProps = {
  navLinks: MainHeaderNavigationProps['navLinks'];
  open: boolean;
  selectedDispensary: SiteWideWrapperProps['selectedDispensary'];
  setClose: () => void;
};

export const HeaderDrawer = ({
  navLinks,
  open,
  selectedDispensary,
  setClose
}: HeaderDrawerProps): JSX.Element | null => {
  const linkClass = 'text-primary title-m font-bold pointer';
  const [drawerLinks, setDrawerLinks] = useState([navLinks]);

  return (
    <Sidebar
      visible={open}
      position="left"
      onHide={setClose}
      showCloseIcon={false}
      header={
        <div className="d-flex header align-items-center justify-content-between bg-primary">
          <span className="logo">
            <Logo />
          </span>
          <button
            aria-label="close"
            onClick={setClose}
            className="bg-white pointer drawer-close-icon">
            <CloseIcon />
          </button>
        </div>
      }
      className={open ? 'disable-scroll header-drawer-sidebar' : ''}>
      <ul className="drawer-links margin-0 padding-0">
        {drawerLinks.length > 1 ? (
          <li className="margin-bottom-24">
            <a
              className={`${linkClass} d-flex align-items-center gap-5`}
              role="button"
              tabIndex={0}
              onClick={() => {
                const currDrawerLinks = [...drawerLinks];
                currDrawerLinks.shift();
                if (currDrawerLinks[0]) {
                  setDrawerLinks(currDrawerLinks);
                } else {
                  setDrawerLinks([navLinks]);
                }
              }}>
              <BackArrow />
              Back
            </a>
          </li>
        ) : null}
        {drawerLinks[0]?.map((link) => (
          <li className="margin-bottom-24" key={link.title}>
            {link.link?.length ? (
              <a
                className={`${linkClass} underline`}
                href={link.link}
                onClick={setClose}>
                {link.title}
              </a>
            ) : (
              <a
                role="navigation"
                tabIndex={0}
                onClick={(e) => {
                  e.preventDefault();
                  const currDrawerLinks = [...drawerLinks];
                  if (link.sublinks?.length) {
                    currDrawerLinks.unshift(link.sublinks);
                    setDrawerLinks(currDrawerLinks);
                  } else {
                    setDrawerLinks([navLinks]);
                  }
                }}
                className={linkClass}>
                {link.title}
              </a>
            )}
          </li>
        ))}
        {drawerLinks.length === 1 ? (
          <li>
            <a
              className={`${linkClass} underline`}
              href={
                selectedDispensary ? selectedDispensary.shopLink : '/locations'
              }
              onClick={setClose}>
              Shop now
            </a>
          </li>
        ) : null}
      </ul>
    </Sidebar>
  );
};
