import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';
import { StoreCard } from '../StoreCard';
import { StoreCardDispensary } from 'services';

import './multi-text-block.scss';

export type MultiTextBlockProps = {
  align: 'left' | 'center';
  anchor: string;
  columns: {
    content: string;
  }[];
  ctaButtons?: CTAButtonProps[];
  heading?: string;
  imageFit?: string;
  stores?: StoreCardDispensary[];
};

export const MultiTextBlock = ({
  align,
  anchor,
  columns,
  ctaButtons = [],
  heading,
  imageFit,
  stores = []
}: MultiTextBlockProps) => {
  return (
    <PackageComponentWrapper
      additionalClass="m-container-tb"
      childrenClass="container-lr"
      minWidth={false}>
      <div id={anchor} className="MultiTextBlock">
        {heading ? (
          <Markdown content={heading} additionalClass={`text-${align}`} />
        ) : null}
        <div className="column-wrapper d-flex justify-content-between">
          {columns.map((col) => (
            <div
              key={col.content}
              className={`column ${imageFit === 'none' ? 'as-is' : 'full-width'}`}>
              <Markdown
                content={col.content}
                additionalClass={`text-${align}`}
              />
            </div>
          ))}
        </div>
        {ctaButtons.length ? (
          <div
            className={`cta-button-group text-${align} margin-top-16 margin-bottom-16 d-flex`}>
            {ctaButtons.map((cta) => (
              <CTAButton {...cta} key={cta.url} />
            ))}
          </div>
        ) : null}
        {stores.length ? (
          <div data-store-align={align} className="stores-group d-flex">
            {stores.map((store) => (
              <StoreCard key={store.uid} dispensary={store} type="location" />
            ))}
          </div>
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
