import Image from 'next/image';
import classNames from 'classnames';

import { ImageType } from '../../../models/types';
import { CTAButton, CTAButtonProps } from '../../CTAButton';
import { imgPath } from '../../../utils/imgPath';
import { Markdown } from '../../Markdown';

import './text-image-block.scss';

export type TextImageBlockProps = {
  align: 'center' | 'left';
  id: number;
  ctabutton?: CTAButtonProps | null;
  content: string;
  image: {
    desktop: ImageType;
    mobile?: ImageType | null;
  };
  layout: 'left' | 'right';
  variant: 'primary' | 'secondary';
};

export const TextImageBlock = ({
  align = 'left',
  ctabutton,
  content,
  image,
  layout = 'right',
  isMobile
}: TextImageBlockProps & { isMobile: boolean }): JSX.Element | null => {
  const responsiveImage =
    isMobile && image.mobile ? image.mobile : image.desktop;

  return (
    <div
      className={classNames('ti-block d-flex', {
        reverse: layout === 'right'
      })}>
      <div className="image-container">
        <Image
          src={imgPath(responsiveImage.url)}
          alt={(responsiveImage as ImageType).alternativeText || ''}
          width={(responsiveImage as ImageType).width}
          height={(responsiveImage as ImageType).height}
          priority
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
        />
      </div>
      <div
        className={`text-container text-white d-flex flex-column justify-content-center text-${align}`}>
        <Markdown content={content} />
        {ctabutton && <CTAButton {...ctabutton} fullWidthMobile={false} />}
      </div>
    </div>
  );
};
