import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { AuthStatus, ForgotData, forgotPassword } from 'services';
import { validationErrorCheck } from '../../../../utils/forms';
import { isEmailValid } from '../../../../utils/validators';

import { CTAButton } from '../../../CTAButton';
import { AccountConfirmationForm } from './confirmation';
import { FormStatus } from '../../../FormBuilder/Account/status';
import { TextInput } from '../../../FormBuilder/components/TextInput';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { useSiteWideContext } from '../../../../hooks/siteWideContext';

export type ForgotProps = {
  email?: string;
};

export const ForgotPasswordForm = ({ email = '' }: ForgotProps) => {
  const { setAccountDrawerMode } = useSiteWideContext();
  const [loading, setLoading] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [error, setError] = useState<AuthStatus>({
    text: ''
  });

  const formik = useFormik({
    initialValues: {
      email
    },
    validateOnChange: false,
    validate: (data: ForgotData) => {
      const errors = {
        email: !data.email
          ? 'Please enter a valid email address'
          : !isEmailValid(data.email)
            ? 'Invalid email address (e.g. example@email.com).'
            : ''
      };

      return validationErrorCheck(errors);
    },
    onSubmit: async (values: ForgotData) => {
      setLoading(true);
      const submit = await forgotPassword({
        ...values,
        setError
      });
      setLoading(false);

      if (submit) setConfirmation(true);
    }
  });

  useEffect(() => {
    if (email) {
      setAutoSubmit(true);
    }
  }, []);

  useEffect(() => {
    if (autoSubmit) formik.handleSubmit();
  }, [autoSubmit]);

  if (autoSubmit || (showConfirmation && formik.values.email)) {
    // Markdown is converting this into an anchor
    const email = formik.values.email.replace('@', '&#64;');
    return (
      <AccountConfirmationForm
        content={`We sent an email to **${email}** with a link to reset your password.`}
        title="Reset Link Sent"
      />
    );
  }

  return (
    <PackageComponentWrapper>
      <form className="account-form" onSubmit={formik.handleSubmit}>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Forgot Password</h2>
          {error.text ? <FormStatus {...error} type="error" /> : null}
          <p className="text-primary">
            Please enter your email address and we’ll send you a link that will
            allow you to reset your password.
          </p>
        </div>
        <div className="sidebar-form-padded">
          <TextInput
            title="Email"
            type="email"
            field="email"
            formik={formik}
            required={true}
            autoComplete="email"
          />
          <CTAButton
            type="submit"
            disabled={!formik.dirty}
            loading={loading}
            text="Send Password Reset Link"
            variant="primary"
            fullWidth={true}
          />
          <div className="margin-top-16 margin-bottom-20 text-center">
            <a
              role="button"
              className="underline pointer text-primary"
              tabIndex={0}
              onClick={() => setAccountDrawerMode('login')}>
              Cancel
            </a>
          </div>
        </div>
      </form>
    </PackageComponentWrapper>
  );
};
