import { MobileCategoryTile as Default } from './default';
import { Loader } from './loader';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';

import './category-tile.scss';

export type MobileCategoryTileProps = {
  title: string;
  link: string;
  isLoading: false;
};

type LoadingProps = {
  isLoading: true;
};

export const MobileCategoryTile = ({
  props
}: {
  props: MobileCategoryTileProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <ErrorBoundary component="mobile-category-tile-loader">
      <Loader />
    </ErrorBoundary>
  ) : (
    <ErrorBoundary component="mobile-category-tile">
      <Default {...props} />
    </ErrorBoundary>
  );
