import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './empty-cart.scss';

export const EmptyCart = (): JSX.Element | null => {
  return (
    <ErrorBoundary component="empty-cart">
      <div
        className="empty-cart d-flex flex-column justify-content-center align-items-center"
        data-test="empty-cart">
        <h4 className="text-primary margin-bottom-0 title-l text-tertiary-dark">
          You have no items in your cart
        </h4>
      </div>
    </ErrorBoundary>
  );
};
