import { DatalayerAnalytics } from 'services';
import { SubcategoryTileProps } from '.';

export const SubcategoryTile = ({
  props
}: {
  props: SubcategoryTileProps;
}): JSX.Element | null => {
  const { filter, onSelectFilter } = props;

  return (
    <button
      data-selected={filter.isSelected}
      onClick={() => {
        try {
          DatalayerAnalytics.pushFilterInteraction({
            action: filter.isSelected ? 'remove' : 'add',
            filter_type: 'chip'
          });
        } catch (err) {
          console.error(err);
        }

        onSelectFilter('Subcategories', filter);
      }}
      className="bg-white body-l font-bold text-primary subcategory-button sm-border-radius pointer">
      {filter.label}
    </button>
  );
};
