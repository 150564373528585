'use client';

import { SocialIcons } from '../SocialIcons';
import { FooterProps } from '.';
import { Markdown } from '../Markdown';
import { CTAButton } from '../CTAButton';
import { SharedFooterNavigation } from './shared';

import Logo from '../../assets/svgs/header/full-logo-teal-ecom-footer.svg';

export const MainFooterNavigation = ({
  copyrightInfo,
  disclaimer,
  footerLinks,
  socialMediaLinks
}: Omit<FooterProps, 'legalLinks' | 'type'>): JSX.Element | null => {
  const servicesLinks = footerLinks?.find(
    (fLink) => fLink.heading === 'Services'
  );
  const otherLinks = footerLinks?.find(
    (fLink) => fLink.heading === 'Other links'
  );

  return (
    <>
      {/* DESKTOP */}
      <div
        className="main-footer-nav-desktop padding-40 d-flex flex-column gap-40"
        data-test="footer-container">
        <div className="logo d-flex justify-content-between padding-bottom-20">
          <Logo />
          {servicesLinks?.links.length ? (
            <div className="d-flex gap-60 services-link-group">
              {servicesLinks.links.map((sLink) => (
                <CTAButton
                  key={sLink.linkName}
                  variant="secondary"
                  url={sLink.url}
                  text={sLink.linkName}
                />
              ))}
            </div>
          ) : null}
        </div>
        {footerLinks ? (
          <div className="footer-links d-flex justify-content-between gap-20">
            <div className="disclaimer d-flex flex-column gap-20">
              {disclaimer ? (
                <div>
                  <Markdown content={disclaimer} additionalClass="text-white" />
                </div>
              ) : null}
              {copyrightInfo ? (
                <span className="body-sm text-white">{copyrightInfo}</span>
              ) : null}
              <ul className="margin-0 padding-0 d-flex flex-column gap-20">
                {otherLinks?.links.map((sLink) => (
                  <li key={sLink.linkName}>
                    <a
                      className="body-l font-bold text-white  underline"
                      href={sLink.url}>
                      {sLink.linkName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {footerLinks.map((fl) => {
              if (
                fl.heading &&
                fl.heading !== 'Services' &&
                fl.heading !== 'Other links'
              ) {
                return (
                  <div className="footerlink-column" key={fl.heading}>
                    <div className="margin-bottom-20">
                      <span className="caption text-white">{fl.heading}</span>
                    </div>
                    <ul className="margin-0 padding-0 d-flex flex-column gap-20">
                      {fl.links.map((fLink) => (
                        <li key={fLink.linkName}>
                          <a
                            className="body-l font-bold text-white"
                            href={fLink.url}>
                            {fLink.linkName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              }
            })}
          </div>
        ) : null}
        {socialMediaLinks ? (
          <div className="corp-social-media" data-test="corp-social-icons">
            <SocialIcons
              overrideClass="d-flex justify-content-end gap-50"
              size="small"
              socialMediaLinks={socialMediaLinks}
              variant="secondary"
            />
          </div>
        ) : null}
      </div>
      {/* MOBILE */}
      <div className="main-footer-nav-mobile">
        <SharedFooterNavigation
          copyrightInfo={copyrightInfo}
          disclaimer={disclaimer}
          footerLinks={footerLinks}
          socialMediaLinks={socialMediaLinks}
          type="main"
        />
      </div>
    </>
  );
};
