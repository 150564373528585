import { LoadingSkeleton } from '../../Loader/Skeleton';

export const Loader = () => {
  return (
    <div className="pdp-image-carousel d-flex gap-32">
      <div className="carousel-wrapper">
        <div style={{}} className="carousel">
          <div className="carousel-el">
            <div>
              <LoadingSkeleton width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
