import {
  ConfigurationFormType,
  CS_Article,
  CS_ArticleGrid_ArticleItem,
  CS_ArticleGrid_NewsItem,
  CS_CuraleafPage_ArticleGrid,
  CS_CuraleafPage_CTAButton,
  CS_CuraleafPage_CTADivider,
  CS_CuraleafPage_EmbeddableForm,
  CS_CuraleafPage_FAQBlock,
  CS_CuraleafPage_HeroBanner,
  CS_CuraleafPage_HeroBannerCarousel,
  CS_CuraleafPage_ImageBlock,
  CS_CuraleafPage_InstagramGrid,
  CS_CuraleafPage_InstructionsBlock,
  CS_CuraleafPage_MultiTextBlock,
  CS_CuraleafPage_ProductCard,
  CS_CuraleafPage_PromoCarousel,
  CS_CuraleafPage_RedirectSearch,
  CS_CuraleafPage_TextImageBlock,
  CS_CuraleafPage_TextImageBlockCarousel,
  CS_CuraleafPage_VideoBlock,
  CS_Dispensary,
  CS_EmbeddableFormWrapper,
  CS_Generated_WebsiteLayout,
  CS_Image_Mandatory,
  CS_Image_Optional,
  CS_NewsItem,
  CS_State,
  CS_StateAgeVerification,
  CS_Website_Footer,
  CS_Website_WideBanner,
  FormType,
  StatesNDispensaries,
  StoreCardDispensary
} from 'services';

import {
  ArticleGridMap,
  CTADividerMap,
  ContactUsMap,
  FAQBlockMap,
  HeroBannerCarouselMap,
  HeroBannerMap,
  ImageBlockMap,
  InstagramGridMap,
  InstructionsBlockMap,
  MultiTextBlockMap,
  OptInMap,
  ProductCardMap,
  PromoCarouselMap,
  RedirectSearchMap,
  TextImageBlockCarouselMap,
  TextImageBlockMap,
  VideoBlockMap
} from '../models/contentstackMap';
import {
  FormsOptions,
  ImageType,
  MP4Type,
  MandatoryImageType,
  SocialMediaLinksData
} from '../models/types';
import { HeaderLink } from '../components/HeaderNavigation';
import { UserLink } from '../components/HeaderNavigation/Drawer/userMenu';
import { SocialIconsProps } from '../components/SocialIcons';
import { CTAButtonProps } from '../components/CTAButton';
import { WideBannerProps } from '../components/WideBanner';
import { isMP4 } from './validators';
import { AgeVerificationProps } from '../components/AgeVerification';
import { ContactUsProps } from '../components/FormBuilder/contact-us';
import { MarketingIntakeProps } from '../components/FormBuilder/marketing-intake';
import { FooterProps } from '../components/FooterNavigation';
import { formatAbbrvDate, formatDate } from './date';
import { ArticleCardProps } from '../components/ArticleGrid/ArticleCard';
import { dispensaryMap } from './consolidateDispensaryMap';

export const mandatoryImageMap = (
  image: CS_Image_Mandatory
): MandatoryImageType => {
  return {
    alternativeText: image.edges[0]?.node.description || '',
    width: image.edges[0]?.node.dimension.width,
    height: image.edges[0]?.node.dimension.height,
    url: image.edges[0]?.node.url
  };
};

export const optionalImageMap = (
  image: CS_Image_Optional
): MandatoryImageType | null =>
  image?.edges[0]
    ? {
        alternativeText: image.edges[0]?.node.description || '',
        width: image.edges[0]?.node.dimension.width,
        height: image.edges[0]?.node.dimension.height,
        url: image.edges[0]?.node.url
      }
    : null;

export const footerMap = (
  footer: CS_Website_Footer,
  options?: {
    optInCallBackFunc?: () => void;
  }
): FooterProps => {
  return {
    disclaimer:
      footer.footer_disclaimer && footer.footer_disclaimer.length
        ? footer.footer_disclaimer
        : undefined,
    copyrightInfo: footer.copyright_info,
    legalLinks: footer.legal_links.length
      ? footer.legal_links.map((link, i: number) => ({
          id: i,
          url: link.url,
          linkText: link.link_text,
          page: link.pageConnection.edges[0]
            ? {
                id: i,
                title: link.pageConnection.edges[0].node.title,
                slug: link.pageConnection.edges[0].node.slug
              }
            : undefined
        }))
      : [],
    footerLinks: footer.footer_links.map((flinks, i: number) => ({
      heading: flinks.heading,
      id: i,
      url: flinks.url && flinks.url.length ? flinks.url : undefined,
      links: flinks.links.map((link, i: number) => ({
        linkName: link.link_name,
        article_page: undefined,
        page: link.pageConnection.edges[0]
          ? {
              id: i,
              title: link.pageConnection.edges[0].node.title,
              slug: link.pageConnection.edges[0].node.slug
            }
          : undefined,
        url: link.url && link.url.length ? link.url : undefined,
        id: i,
        callBackFunc:
          link.link_name === 'Sign Up For Our Mailing List' &&
          options &&
          options.optInCallBackFunc
            ? options.optInCallBackFunc
            : undefined
      }))
    })),
    type: 'main',
    socialMediaLinks: footer.social_media_links.length
      ? footer.social_media_links.map((sm, i) => ({
          ...sm,
          id: i
        }))
      : []
  };
};

export const ageVerificationMap = (
  age: CS_StateAgeVerification
): Omit<AgeVerificationProps, 'submit'> => {
  return {
    backgroundImage: optionalImageMap(age.background_imageConnection),
    exitLinkText: age.exit_link_text,
    header: age.header,
    stateConfig: age.state_config,
    titles: age.titles
  };
};

export const wideBannerMap = (
  widebanner: CS_Website_WideBanner
): Omit<WideBannerProps, 'onClose'> => {
  const banners = widebanner.banners.map((banner, i) => ({
    id: i,
    link: {
      id: i,
      desktop_text: banner.desktop_text,
      mobile_text: banner.mobile_text,
      url: banner.url
    }
  }));

  return {
    widebanner: banners
  };
};

export const textImageBlockMap = (
  data: CS_CuraleafPage_TextImageBlock
): TextImageBlockMap => {
  return {
    __typename: data.__typename,
    isMobile: false,
    image: mandatoryImageMP4Map(data.text_image_block.imageConnection),
    content: data.text_image_block.content,
    ctabutton: ctaButtonMap(data.text_image_block.cta_button, false),
    layout: data.text_image_block.layout,
    anchor: data.text_image_block.anchor
  };
};

export const textImageBlockCarouselMap = (
  data: CS_CuraleafPage_TextImageBlockCarousel
): TextImageBlockCarouselMap => {
  return {
    __typename: data.__typename,
    anchor: data.text_image_block_carousel.anchor,
    blocks: data.text_image_block_carousel.blocks.map((block, i) => ({
      align: block.align,
      content: block.content,
      ctabutton: ctaButtonMap(block.cta_button, true),
      id: i,
      image: {
        desktop: mandatoryImageMap(block.image.desktopConnection),
        mobile: optionalImageMap(block.image.mobileConnection)
      },
      layout: data.text_image_block_carousel.layout,
      variant: data.text_image_block_carousel.variant
    })),
    isMobile: false
  };
};

export const multiImageTextBlockMap = (
  data: CS_CuraleafPage_MultiTextBlock
): MultiTextBlockMap => {
  const stores: StoreCardDispensary[] = [];
  try {
    if (data.multi_text_image_block.dispensary_card?.length) {
      data.multi_text_image_block.dispensary_card.map((d) => {
        if (d.dispensaryConnection.edges[0]?.node) {
          // converting CS_DispensaryManualConfig to be CS_Dispensary
          const dispensary = d.dispensaryConnection.edges[0]?.node;
          const { auto_syncConnection, statesConnection } = dispensary;
          const mappedDispensary = {
            ...d.dispensaryConnection.edges[0]?.node,
            ...auto_syncConnection.edges[0].node,
            day_times: JSON.parse(auto_syncConnection.edges[0].node.day_times),
            distance: '',
            state: statesConnection.edges[0]?.node,
            uid: d.dispensaryConnection.edges[0].node.unique_id
          } as CS_Dispensary;

          // converting CS_Dispensary to be StoreCardDispensary
          const storeCardDispensary = dispensaryMap(
            mappedDispensary,
            undefined,
            'locations'
          ) as StoreCardDispensary | null;

          if (storeCardDispensary) stores.push(storeCardDispensary);
        }
      });
    }
  } catch (err) {
    console.error('Failed to add dispensary_card in multiImageTextBlockMap');
  }
  const ctaButtons = ctaButtonsMap(
    data.multi_text_image_block.cta_buttons,
    true
  );
  return {
    __typename: data.__typename,
    align: data.multi_text_image_block.align,
    anchor: data.multi_text_image_block.anchor,
    columns: data.multi_text_image_block.column.map((col) => ({
      content: col.content
    })),
    ctaButtons,
    heading: data.multi_text_image_block.heading,
    imageFit: data.multi_text_image_block.image_fit || undefined,
    stores
  };
};

export const ctaButtonMap = (
  cta: CS_CuraleafPage_CTAButton,
  isRedesign?: boolean
): CTAButtonProps | null => {
  if (cta && cta.text.length) {
    let variant = cta.variant;
    if (!isRedesign) {
      if (variant === 'secondary') {
        variant = 'primary';
      } else if (variant === 'tertiary') {
        variant = 'secondary';
      }
    }
    if (variant === 'primary' || variant === 'secondary') {
      return {
        text: cta.text,
        url: cta.link || '',
        variant
      };
    }
  }
  return null;
};

export const ctaButtonsMap = (
  ctas: CS_CuraleafPage_CTAButton[] | null,
  isRedesign?: boolean
): CTAButtonProps[] =>
  ctas?.length
    ? ctas.reduce((arr: CTAButtonProps[], ctb) => {
        const cta = ctaButtonMap(ctb, isRedesign);
        if (cta) {
          arr.push(cta);
        }
        return arr;
      }, [])
    : [];

export const heroBannerMap = (
  data: CS_CuraleafPage_HeroBanner
): HeroBannerMap => {
  return {
    __typename: data.__typename,
    anchor: data.hero_banner.anchor,
    content: data.hero_banner.content,
    ctabuttons: ctaButtonsMap(data.hero_banner.cta_button, true),
    image: {
      desktop: mandatoryImageMP4Map(data.hero_banner.image.desktopConnection),
      mobile: optionalImageMap(data.hero_banner.image.mobileConnection)
    },
    isMobile: false
  };
};

export const heroBannerCarouselMap = (
  data: CS_CuraleafPage_HeroBannerCarousel
): HeroBannerCarouselMap => {
  return {
    __typename: data.__typename,
    anchor: data.hero_banner_carousel.anchor,
    banners: data.hero_banner_carousel.banner.map((b, i) => ({
      content: b.content,
      ctabuttons: ctaButtonsMap(b.cta_button, true),
      heading: b.heading,
      id: i,
      image: {
        desktop: mandatoryImageMP4Map(b.image.desktopConnection),
        mobile: optionalImageMap(b.image.mobileConnection)
      },
      mobileVariant: b.mobile_variant,
      showLogo: b.show_logo,
      variant: b.variant
    })),
    isMobile: false
  };
};

export const imageBlockMap = (
  data: CS_CuraleafPage_ImageBlock
): ImageBlockMap => {
  return {
    __typename: data.__typename,
    anchor: data.image_block.anchor || '',
    image: mandatoryImageMap(data.image_block.desktopConnection),
    mobileImage: optionalImageMap(data.image_block?.mobileConnection),
    cta: data.image_block.cta?.[0],
    isMobile: false
  };
};

export const productCardMap = (
  data: CS_CuraleafPage_ProductCard
): ProductCardMap => {
  return {
    __typename: data.__typename,
    anchor: data.product_card.anchor,
    ctaButton: ctaButtonMap(data.product_card.cta_button, true),
    features: data.product_card.features,
    heading: data.product_card.heading,
    image: mandatoryImageMap(data.product_card.imageConnection)
  };
};

export const promoCarouselMap = (
  data: CS_CuraleafPage_PromoCarousel
): PromoCarouselMap => {
  return {
    __typename: data.__typename,
    auto_slide_in_seconds: data.promo_carousel.auto_slide_in_seconds || 7,
    banners: data.promo_carousel.promo_banner.map((banner, i) => ({
      activeImageIndex: i,
      backgroundHexColor: banner.background_color_hex || '#eaeaea', //secondary-primary
      content: banner.content,
      ctaButton: ctaButtonMap(banner.cta_button, true),
      id: i,
      image: mandatoryImageMap(banner.image.desktopConnection),
      layout: banner.layout,
      mobileImage: optionalImageMap(banner.image.mobileConnection)
    })),
    isMobile: false,
    variant: data.promo_carousel.variant
  };
};

export const redirectMap = (
  data: CS_CuraleafPage_RedirectSearch
): RedirectSearchMap => {
  return {
    __typename: data.__typename,
    anchor: data.redirect_search.anchor,
    backgroundImage: mandatoryImageMap(
      data.redirect_search.background_imageConnection
    ),
    buttonText: data.redirect_search.button_text,
    heading: data.redirect_search.heading,
    inputPlaceholder: data.redirect_search.input_placeholder,
    searchUrl: data.redirect_search.search_url
  };
};

export const instagramGridMap = (
  data: CS_CuraleafPage_InstagramGrid
): InstagramGridMap => {
  const images = data.instagram_grid.image.map((img) => ({
    image: mandatoryImageMap(img.imageConnection),
    link: img.link
  }));

  return {
    __typename: data.__typename,
    images
  };
};

export const faqBlockMap = (faq: CS_CuraleafPage_FAQBlock): FAQBlockMap => {
  return {
    __typename: faq.__typename,
    anchor: faq.faq_block.anchor,
    content: faq.faq_block.content,
    faqs: faq.faq_block.faqs.map((faq) => ({
      ...faq
    }))
  };
};

export const articleGridMap = (
  data: CS_CuraleafPage_ArticleGrid
): ArticleGridMap => {
  return {
    __typename: data.__typename,
    heading: data.article_grid.heading,
    articles: data.article_grid.articlesConnection.edges.map(
      (a: CS_ArticleGrid_NewsItem | CS_ArticleGrid_ArticleItem, i) => {
        return articleCardMap(a.node, i);
      }
    )
  };
};

export const articleCardMap = (
  article: CS_Article | CS_NewsItem,
  index: number
): ArticleCardProps => {
  const author =
    (article as CS_Article).author || (article as CS_NewsItem).publisher;

  return {
    author,
    date:
      author && author.length > 10
        ? formatAbbrvDate(article.published_date)
        : formatDate(article.published_date),
    id: index,
    thumbnail: (article as CS_NewsItem).imageConnection
      ? mandatoryImageMap((article as CS_NewsItem).imageConnection)
      : mandatoryImageMap(
          (article as CS_Article).article_card.thumbnailConnection
        ),
    title: article.title,
    type: (article as CS_Article).category || 'news',
    slug: (article as CS_Article).slug || (article as CS_NewsItem).website_url
  };
};

export const videoBlockMap = (
  data: CS_CuraleafPage_VideoBlock
): VideoBlockMap => {
  return {
    __typename: data.__typename,
    url: data.video_block.videoConnection.edges[0].node.url
  };
};

export const staticEmbeddableFormMap = (
  form: CS_CuraleafPage_EmbeddableForm,
  formConfig: ConfigurationFormType,
  statesNdispensaries: StatesNDispensaries[]
): ContactUsMap | OptInMap | null => {
  const data = form.embeddable_form.formConnection.edges[0].node;

  const mappedData = {
    __typename: form.__typename,
    buttonText: formConfig.general.submit_button_text,
    confirmation: {
      title: data.confirmation.title,
      content: data.confirmation.content,
      image: optionalImageMap(data.confirmation.imageConnection)
    },
    disclaimer: formConfig.general.disclaimer,
    formUid: data.system.uid,
    image: optionalImageMap(data.imageConnection),
    statesNdispensaries,
    subtitle: data.subtitle || '',
    title: data.form_title
  };

  switch (data.type) {
    case 'Contact Us': {
      return {
        ...mappedData,
        statesNdispensaries,
        topics: formConfig.contact_us.topics || [],
        type: 'Contact Us'
      };
    }
    case 'Opt In': {
      return {
        ...mappedData,
        statesNdispensaries: statesNdispensaries.filter(
          (sd) => sd.dispensaries.length
        ),
        type: 'Opt In'
      };
    }
    default: {
      return null;
    }
  }
};

export const embeddableFormMap = (
  form: CS_EmbeddableFormWrapper['Embeddable_Form'],
  options: FormsOptions & {
    type: 'Contact Us' | 'Opt In';
  }
): MarketingIntakeProps | ContactUsProps => {
  const data = form.formConnection.edges[0].node;
  const statesNdispensaries = data.statesConnection.edges.map(
    (state: { node: CS_State }) => ({
      state: state.node.title,
      dispensaries: data.dispensariesConnection.edges.reduce(
        (
          arr: StatesNDispensaries['dispensaries'],
          dispensary: { node: CS_Dispensary }
        ) => {
          if (dispensary.node.state.title === state.node.title) {
            arr.push({
              name: dispensary.node.friendly_name
            });
          }
          return arr;
        },
        []
      )
    })
  );

  const mappedData = {
    buttonText: options.config.general.submit_button_text,
    disclaimer: options.config.general.disclaimer,
    submitForm: (data: FormType) => options.submitForm(data),
    title: data.form_title,
    subtitle: data.subtitle || '',
    image: optionalImageMap(data.imageConnection) || undefined,
    closeButton: options.closeButton
      ? {
          callBackFunc: options.closeButton.callBackFunc,
          text: options.closeButton.text,
          variant: options.closeButton.variant
        }
      : undefined,
    confirmation: {
      title: data.confirmation.title,
      content: data.confirmation.content,
      image: optionalImageMap(data.confirmation.imageConnection) || undefined,
      callback:
        options && options.confirmationCallback
          ? options.confirmationCallback
          : undefined
    },
    formUid: data.system.uid
  };

  switch (options.type) {
    case 'Contact Us': {
      return {
        ...mappedData,
        statesNdispensaries,
        topics: options.config.contact_us.topics || []
      };
    }
    case 'Opt In': {
      return {
        ...mappedData,
        statesNdispensaries: statesNdispensaries.filter(
          (sd) => sd.dispensaries.length
        )
      };
    }
  }
};

export const mandatoryImageMP4Map = (
  image: CS_Image_Mandatory
): ImageType | MP4Type => {
  return isMP4(image.edges[0].node.url)
    ? {
        url: image.edges[0].node.url
      }
    : {
        alternativeText: image.edges[0].node.description || '',
        width: image.edges[0].node.dimension.width,
        height: image.edges[0].node.dimension.height,
        url: image.edges[0].node.url
      };
};

export const ctaDividerMap = (
  data: CS_CuraleafPage_CTADivider
): CTADividerMap => {
  return {
    __typename: data.__typename,
    ctabutton: {
      text: data.cta_divider.logged_out_cta_text || '',
      url: data.cta_divider.link || ''
    },
    mode: data.cta_divider.mode || 'dark',
    text: data.cta_divider.text || '',
    loggedInText: data.cta_divider.logged_in_cta_text || ''
  };
};

export const instructionsBlockMap = (
  data: CS_CuraleafPage_InstructionsBlock
): InstructionsBlockMap => {
  return {
    __typename: data.__typename,
    heading: data.instructions_block.heading,
    isMobile: false,
    steps: data.instructions_block.steps.length
      ? data.instructions_block.steps.filter((x): x is string => x !== null)
      : []
  };
};

export type LayoutMap = {
  header: HeaderLink;
  socialLinks: SocialMediaLinksData[];
  userLinks: UserLink[];
};
export const layoutMap = (layout: CS_Generated_WebsiteLayout): LayoutMap => {
  let header: HeaderLink = [];
  let socialLinks: SocialIconsProps['socialMediaLinks'] = [];
  let userLinks: UserLink[] = [];

  if (layout) {
    if (layout.header?.menu_items) {
      header = layout.header.menu_items.map((item) => {
        if (item) {
          return {
            title: item.title,
            link: item.url_destination,
            pixelLength: item.pixel_length,
            sublinks: item.sub_menu_items?.map((sub) => {
              if (sub) {
                return {
                  title: sub.sub_menu_title,
                  link: sub.sub_menu_url_destination
                };
              }
            })
          };
        }
      }) as HeaderLink;
    }
    if (layout.social_links) {
      socialLinks = layout.social_links.reduce(
        (arr: SocialIconsProps['socialMediaLinks'], link, id) => {
          if (link && link.platform && link.url_destination) {
            arr.push({
              id,
              corporate_type: link.platform,
              url: link.url_destination
            });
          }
          return arr;
        },
        []
      );
    }
    if (layout.logged_in_menu_items) {
      userLinks = layout.logged_in_menu_items.reduce(
        (arr: UserLink[], item) => {
          if (item && item.title && item.url_destination) {
            arr.push({
              title: item.title,
              link: item.url_destination
            });
          }
          return arr;
        },
        []
      );
    }
  }
  return {
    header,
    socialLinks,
    userLinks
  };
};
