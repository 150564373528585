'use client';

import { HeaderNavigation as Web } from './web';
import { KioskHeaderNavigation } from './kiosk';
import { UserLink } from './Drawer/userMenu';
import { SocialIconsProps } from '../SocialIcons';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './header-navigation.scss';

export type HeaderLink = {
  title: string;
  link?: string;
  pixelLength?: number;
  sublinks?: Omit<HeaderLink, 'sublinks'> & {
    sublinks?: { title: string; link: string };
  };
}[];

export type HeaderNavigationProps = {
  navLinks: HeaderLink;
  navOpen: boolean;
  socialLinks: SocialIconsProps['socialMediaLinks'];
  toggleNav: (val: boolean) => void;
  userLinks: UserLink[];
  isKiosk: false;
};

export type KioskHeaderNavigationProps = {
  isKiosk: true; // had to use this instead of ENV because of typescript.
};

export const HeaderNavigation = (
  props: HeaderNavigationProps | KioskHeaderNavigationProps
): JSX.Element | null => {
  if (props.isKiosk) {
    return (
      <ErrorBoundary component="kiosk-header">
        <KioskHeaderNavigation />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary component="header">
      <Web {...props} />
    </ErrorBoundary>
  );
};
