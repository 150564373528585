import classNames from 'classnames';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { LoadingSkeleton } from '../../Loader/Skeleton';
import { FilterAccordion } from './FilterAccordion';

export const Loader = (): JSX.Element | null => {
  const { width } = useSiteWideContext();
  const accordions = new Array(3).fill(undefined);
  const isTablet = width && width < 1024;

  return (
    <PackageComponentWrapper minWidth={false}>
      <div data-test="filter-dt" className="filter-wrapper ft-loader">
        <div
          className={classNames('product-filter padding-20 bg-white', {
            'd-flex flex-column': isTablet,
            'margin-top-30 margin-left-30': !isTablet
          })}
          data-test="filter-accordion-wrapper">
          {isTablet ? (
            <div className="mobile-accordion-header">
              <a>
                <LoadingSkeleton width="20px" />
              </a>
              <h5 className="body-l font-bold text-center">
                <LoadingSkeleton width="100px" />
              </h5>
            </div>
          ) : null}
          {accordions.map((_, i) => (
            <FilterAccordion
              key={i}
              props={{
                isLoading: true
              }}
            />
          ))}
        </div>
        {isTablet ? (
          <div className="results-button">
            <LoadingSkeleton width="btn" />
          </div>
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
