import { Loader } from './loader';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { ProductImageCarousel as Default } from './default';

import './product-image-carousel.scss';
import { Consolidate_Product } from 'services';

export type ProductImageCarouselProps = {
  isLoading: false;
  isTablet: boolean;
  productImages: any[];
  productName: string;
  strainType: Consolidate_Product['strainType'];
};

export type LoadingProps = {
  isLoading: true;
};

export const ProductImageCarousel = (
  props: ProductImageCarouselProps | LoadingProps
) => {
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="product-description">
      <Default {...props} />
    </ErrorBoundary>
  );
};
