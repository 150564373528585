'use client';

import { useEffect } from 'react';
import Image from 'next/image';

import { DatalayerAnalytics, DutchiePlus_PricingType } from 'services';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { BreadCrumbs } from '../components/Breadcrumbs';
import { CTAButton } from '../components/CTAButton';
import { PackageComponentWrapper } from '../components/PackageComponentWrapper';

import './specials-page.scss';

type SpecialsPageTile = {
  id: string;
  image?: string;
  menuType: DutchiePlus_PricingType | 'BOTH';
  productImages: string[];
  scheduleDescription?: string;
  title: string;
  data: any;
};

export type SpecialsPageProps = {
  specials: SpecialsPageTile[];
};

export const SpecialsPage = ({ specials }: SpecialsPageProps) => {
  const { currentPathDispensary } = useDispensaryPathContext();

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: `shop/AllSpecialsPage`,
        consolidateDispensary: currentPathDispensary
      });
    }
  }, [currentPathDispensary]);

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              id: 0,
              urlFragment: currentPathDispensary.shopLink,
              breadcrumbName: 'Home'
            }
          ],
          currentPage: 'Specials',
          isLoading: false
        }}
      />
      <PackageComponentWrapper childrenClass="d-flex flex-column gap-24 container-lr padding-bottom-24">
        <h1 className="h3 text-black text-left margin-bottom-0">Specials</h1>
        <div className="specials-page-grid">
          {specials.map((special) => {
            return (
              <div
                className="specials-page-tile d-flex flex-column padding-16"
                key={special.id}>
                {special.image ? (
                  <div className="specials-image">
                    <Image
                      alt={special.title}
                      fill={true}
                      sizes="100%"
                      src={special.image}
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'center'
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex">
                    {special.productImages.map((pi) => {
                      return (
                        <div className="product-image" key={pi}>
                          <Image
                            alt={special.title}
                            fill={true}
                            sizes="100%"
                            src={pi}
                            style={{
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="margin-top-16 margin-bottom-16 d-flex flex-column gap-8">
                  <h4 className="margin-bottom-0">{special.title}</h4>
                  {special.scheduleDescription ? (
                    <span className="body-sm">
                      {special.scheduleDescription}
                    </span>
                  ) : null}
                </div>
                <div className="st-btn-container">
                  <CTAButton
                    url={`${currentPathDispensary.shopLink}/specials/${special.id}`}
                    text="View Special"
                    fullWidth={true}
                    size="md"
                    variant="secondary"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </PackageComponentWrapper>
    </>
  );
};
