import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { useCarousel } from '../../hooks/useCarousel';
import Carousel from '../Carousel';
import { CarouselDots } from '../Carousel/components';
import { TextImageBlock, TextImageBlockProps } from './Block';

export type TextImageBlockCarouselProps = {
  anchor?: string;
  blocks: TextImageBlockProps[];
  isMobile: boolean;
};

export const TextImageBlockCarousel = ({
  anchor = 'text-image-block-carousel',
  blocks,
  isMobile
}: TextImageBlockCarouselProps): JSX.Element | null => {
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(blocks, false, 7);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  return (
    <PackageComponentWrapper>
      <div
        data-test="text-image-block-carousel"
        id={anchor}
        className="promo-banner-carousel bg-primary">
        <div className="carousel-wrapper">
          <Carousel data={blocks} states={states}>
            {blocks.map((item) => (
              <div
                data-test="carousel-item"
                key={item.id}
                className="carousel-el"
                style={{
                  transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
                }}>
                <TextImageBlock {...item} isMobile={isMobile} />
              </div>
            ))}
          </Carousel>
          {blocks && blocks.length > 1 && (
            <div className="block-dots thumbs d-flex justify-content-center bg-primary">
              <CarouselDots data={blocks} states={states} dotName="sm-dot" />
            </div>
          )}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
