import { EcomProductPageType } from '../../models/productPage';
import { Loader } from './loader';
import { EcomProductGrid as Default } from './default';
import {
  FilterKeys,
  FilterMenuItem,
  FilterMenus
} from './ProductSortFilter/FilterModal';
import { SortBy } from '../../models/types';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { PGPProps } from '../../templates/newProductGridPage';
import { ProductTileType } from 'services';

import './ecom-product-grid.scss';

export type ProductViewType = 'list' | 'grid';

type GridProps = {
  offersLength: number;
  products: ProductTileType[];
  view: ProductViewType;
};

type FilterProps = {
  filters?: FilterMenus;
  isLoading: false;
  onApplyFilter: (updatedFilterMenu: FilterMenus) => void;
  onSelectFilter: (
    header: FilterKeys | 'Sort',
    filter: FilterMenuItem,
    sort?: SortBy
  ) => void;
  pageTitle: string;
  pageType: EcomProductPageType['type'];
  searchParams: PGPProps['searchParams'];
  setProductView: (productView: ProductViewType) => void;
  shopLink: string;
  showBrandCopy?: boolean;
  showSpecialsCopy?: boolean;
  sortBy: SortBy;
  totalProductCount: number;
};

export type EcomProductGridProps = GridProps & FilterProps;

export type LoadingProps = {
  isLoading: true;
  pageData: EcomProductPageType;
  view: ProductViewType;
};

export const EcomProductGrid = ({
  props
}: {
  props: EcomProductGridProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader {...props} />
  ) : (
    <ErrorBoundary component="product-grid">
      <Default {...props} />
    </ErrorBoundary>
  );
