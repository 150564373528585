'use client';

import Image from 'next/image';

import { ImageType } from '../../models/types';
import { CTAButton, CTAButtonProps } from '../CTAButton';
import { Markdown } from '../Markdown';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './herobanner.scss';

export type HeroBannerProps = {
  anchor: string;
  content: string;
  ctabuttons: CTAButtonProps[];
  image: {
    desktop: ImageType;
    mobile?: ImageType | null;
  };
  isMobile: boolean;
};

export const HeroBanner = ({
  anchor = 'hero',
  content,
  ctabuttons,
  image,
  isMobile
}: HeroBannerProps): JSX.Element | null => {
  const responsiveImage =
    isMobile && image.mobile ? image.mobile : image.desktop;

  return (
    <PackageComponentWrapper>
      <div data-test="herobanner" id={anchor} className="herobanner">
        <div className="image">
          <Image
            src={responsiveImage.url}
            alt={responsiveImage.alternativeText || ''}
            width={responsiveImage.width}
            height={responsiveImage.height}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </div>
        <div className="content text-center text-white padding-top-32 padding-bottom-32 container-lr">
          <Markdown content={content} />
          {ctabuttons.length
            ? ctabuttons.map((cta) => (
                <CTAButton
                  key={cta.text}
                  {...cta}
                  variant="primary"
                  fullWidthMobile={false}
                />
              ))
            : null}
        </div>
        <div
          className="content-placeholder text-center text-white padding-top-32 padding-bottom-32 container-lr"
          aria-hidden="true">
          <Markdown content={content} />
          {ctabuttons.length
            ? ctabuttons.map((cta) => (
                <CTAButton
                  key={cta.text}
                  {...cta}
                  variant="primary"
                  fullWidthMobile={false}
                />
              ))
            : null}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
