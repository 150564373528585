import { PriceSection as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { Consolidate_ProductVariant } from 'services';

export type PriceSectionProps = {
  isMobile: boolean;
  variant: Pick<
    Consolidate_ProductVariant,
    'isSpecial' | 'specialPrice' | 'price' | 'option'
  >;
  type: 'pdp' | 'tile' | 'list-tile' | 'cart';
};

export const fixedPrice = (price: number) => {
  if (JSON.stringify(price).includes('.')) {
    return price.toFixed(2);
  }
  return price;
};

export const PriceSection = (props: PriceSectionProps): JSX.Element | null => {
  return (
    <ErrorBoundary component="price-range">
      <Default {...props} />
    </ErrorBoundary>
  );
};
