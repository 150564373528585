'use client';

import { SocialIcons } from '../SocialIcons';
import { FooterAccordion } from './accordion';
import { EmployeeLogin } from './Kiosk/EmployeeLogin';
import { KioskClearDispensaryModal } from './Kiosk/ClearDispensaryModal';
import { FooterProps } from '.';
import { Markdown } from '../Markdown';
import { LegalDialog } from './Kiosk/LegalDialog';
import { CTAButton } from '../CTAButton';

import Logo from '../../assets/svgs/header/full-logo-teal-ecom-footer.svg';

export const SharedFooterNavigation = ({
  copyrightInfo,
  disclaimer,
  footerLinks,
  socialMediaLinks,
  type
}: Omit<FooterProps, 'legalLinks'>): JSX.Element | null => {
  const servicesLinks = footerLinks?.find(
    (fLink) => fLink.heading === 'Services'
  );

  return (
    <div
      className="ecom-footer-nav specials-tray-padding"
      data-test="footer-container">
      <div className="logo">
        {type === 'kiosk' ? <KioskClearDispensaryModal /> : <Logo />}
      </div>
      {type === 'main' && servicesLinks ? (
        <div className="d-flex justify-content-center services-buttons">
          {servicesLinks.links.map((sLink) => (
            <CTAButton
              size="md"
              key={sLink.linkName}
              variant="secondary"
              url={sLink.url}
              text={sLink.linkName}
            />
          ))}
        </div>
      ) : null}
      {footerLinks && type !== 'kiosk' ? (
        <div className="footer-links">
          {footerLinks?.map((fl) => {
            if (fl.heading) {
              return <FooterAccordion link={fl} key={fl.id} />;
            }
          })}
        </div>
      ) : null}
      {socialMediaLinks && (
        <div className="corp-social-media" data-test="corp-social-icons">
          <SocialIcons
            size="normal"
            socialMediaLinks={socialMediaLinks}
            variant="secondary"
          />
        </div>
      )}
      {type === 'kiosk' ? (
        <EmployeeLogin disclaimer={disclaimer || ''} />
      ) : disclaimer ? (
        <>
          <div className="text-center footer-disclaimer">
            <Markdown
              content={disclaimer}
              additionalClass="text-tertiary-dark"
            />
          </div>
        </>
      ) : null}
      {copyrightInfo && (
        <div className="text-center margin-top-16">
          <span className="body-sm text-tertiary-dark">{copyrightInfo}</span>
        </div>
      )}
      {type === 'kiosk' && footerLinks && (
        <LegalDialog legalLinks={footerLinks} />
      )}
    </div>
  );
};
