import { Accordion as PAccordion, AccordionTab } from 'primereact/accordion';

import './accordion.scss';

import Arrow from '../../assets/svgs/arrows/accordion-arrow-lg.svg';

export type AccordionProps = {
  additionalClass?: string;
  content: React.ReactNode;
  header: React.ReactNode;
  mode: 'light' | 'dark';
  variant?: 'primary' | 'secondary' | 'header';
  defaultOpen?: boolean;
};

export const Accordion = ({
  additionalClass = '',
  content,
  header,
  mode = 'light',
  variant = 'primary',
  defaultOpen = false
}: AccordionProps): JSX.Element | null => {
  return (
    <PAccordion
      activeIndex={defaultOpen ? 0 : null}
      collapseIcon={
        <span className="accordion-arrow up">
          <Arrow />
        </span>
      }
      expandIcon={
        <span className="accordion-arrow">
          <Arrow />
        </span>
      }
      className={`curaleaf-accordion ${additionalClass} ${variant} ${mode}`}>
      <AccordionTab
        headerClassName="curaleaf-accordion-header"
        contentClassName="curaleaf-accordion-content"
        header={header}>
        {content}
      </AccordionTab>
    </PAccordion>
  );
};
