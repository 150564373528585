import { FooterNavLinks } from '.';
import { Accordion } from '../Accordion';

export type FooterAccordionProps = {
  link: FooterNavLinks;
};

export const FooterAccordion = ({
  link
}: FooterAccordionProps): JSX.Element | null => {
  return (
    <>
      {link.links.length ? (
        <Accordion
          mode="dark"
          variant="secondary"
          header={
            link.url ? (
              <a className="title-l text-white" href={link.url}>
                {link.heading}
              </a>
            ) : (
              <span className="title-l text-white">{link.heading}</span>
            )
          }
          content={
            <ul className="footer-link-list">
              {link.links.map((footerLink) => {
                if (footerLink.url) {
                  return (
                    <li key={footerLink.id}>
                      <a
                        key={footerLink.id}
                        href={footerLink.url}
                        className="text-white underline">
                        {footerLink.linkName}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          }
        />
      ) : null}
    </>
  );
};
