import Image from 'next/image';

import { imgPath } from '../../../utils/imgPath';
import { imageLoader } from '../../../utils/imageLoader';

import { BrandDescriptionProps } from './index';

import { Accordion } from '../../Accordion';

export const BrandDescription = ({
  productBrand
}: BrandDescriptionProps): JSX.Element | null => {
  if (productBrand && productBrand.description) {
    return (
      <Accordion
        defaultOpen={true}
        variant="primary"
        header={
          <h2 className="title-l text-black padding-top-16 padding-bottom-16 margin-bottom-0">
            About {productBrand.name}
          </h2>
        }
        content={
          <div className="brand-accordion-content">
            <div
              className="brand-description d-flex gap-24"
              data-test="bd-withimage">
              {productBrand.imageUrl ? (
                <div className="image-col">
                  <Image
                    data-test="default-product-img"
                    className="product-img"
                    src={imgPath(productBrand.imageUrl)}
                    alt={productBrand.name}
                    title={`${productBrand.name} logo`}
                    fill={true}
                    loader={imageLoader}
                    sizes="100%"
                  />
                </div>
              ) : null}
              <div className="description-col">
                <div>
                  <p className="body-l text-black margin-bottom-0">
                    {productBrand.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
        mode="light"
      />
    );
  }
  return null;
};
