import { LoadingSkeleton } from '../Loader/Skeleton';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductListTile } from '../ProductListTile';

export const Loader = (): JSX.Element | null => {
  const orderTiles = new Array(3).fill(undefined);
  return (
    <PackageComponentWrapper>
      <div className="order-confirmation container-lr">
        <div className="order-content text-center">
          <h2 className="margin-bottom-10">
            <LoadingSkeleton width="60%" />
          </h2>
          <p>
            <LoadingSkeleton width="20%" />
          </p>
          <p>
            <LoadingSkeleton width="40%" />
          </p>
          <h6 className="margin-bottom-10">
            <LoadingSkeleton width="25%" />
          </h6>
          <p>
            <LoadingSkeleton width="80%" />
          </p>
          <p>
            <LoadingSkeleton width="45%" />
          </p>
        </div>
        <div className="order-summary">
          <div className="header d-flex justify-content-between margin-bottom-10">
            <h6 className="title-l">
              <LoadingSkeleton width="70px" />
            </h6>
            <h6 className="body-xl">
              <LoadingSkeleton width="150px" />
            </h6>
          </div>
          <div className="order-tile-wrapper">
            {orderTiles.map((_, i) => (
              <ProductListTile
                key={i}
                props={{
                  isLoading: true
                }}
              />
            ))}
          </div>
          <div className="order-total-wrapper d-flex justify-content-end">
            <div className="order-total margin-bottom-16 text-black">
              <OrderSummary props={{ isLoading: true }} />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
