'use client';

import { useEffect } from 'react';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';

import {
  CS_EmbeddableFormWrapper,
  CS_Website,
  ConfigurationFormType,
  MarketingFormDataType
} from 'services';
import { useStorage } from '../../../hooks/useStorage';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { FormsOptions } from '../../../models/types';
import {
  MarketingIntakeForm,
  MarketingIntakeProps
} from '../../FormBuilder/marketing-intake';
import { embeddableFormMap } from '../../../utils/contentstackMap';
import { marketingIntakeMap } from '../../../utils/formMap';

import './mailing-opt-in-modal.scss';

export type MailingOptInModalProps = {
  form: CS_Website['opt_in_modal'];
  formConfig: ConfigurationFormType;
  open: boolean;
  toggle: () => void;
};

export const MailingOptInModal = ({
  form,
  formConfig,
  open,
  toggle
}: MailingOptInModalProps) => {
  const [promptedForMailing, setPromptedForMailing] = useStorage(
    'promptedForMailingList',
    '0'
  );
  const { websiteUrl } = useSiteWideContext();

  const now = Date.now();

  const setMaybeLater = () => {
    if (promptedForMailing === '1') {
      toggle();
      return;
    }

    if (
      now < JSON.parse(promptedForMailing) ||
      now > JSON.parse(promptedForMailing) ||
      (typeof promptedForMailing === 'string' &&
        promptedForMailing.length > 2) ||
      !promptedForMailing // ?
    ) {
      const tenDaysLater = (() => {
        const date = new Date();
        date.setDate(date.getDate() + 10);
        return new Date(date).valueOf();
      })();
      setPromptedForMailing(JSON.stringify(tenDaysLater));
      toggle();
    }
  };

  let marketingIntakeForm: CS_EmbeddableFormWrapper['Embeddable_Form'];
  let options: FormsOptions & {
    type: 'Contact Us' | 'Opt In';
  };

  useEffect(() => {
    const updateForms = async () => {
      // NOTE: need this api in order to hide Braze api keys and to keep dispensaries up to date
      const getForm = await axios.get(
        `${websiteUrl}/api/form/${marketingIntakeForm.formConnection.edges[0].node.system.uid}`
      );
      if (getForm.status === 200) {
        marketingIntakeForm.formConnection.edges[0].node.statesConnection =
          getForm.data.forms.statesConnection;
        marketingIntakeForm.formConnection.edges[0].node.dispensariesConnection =
          getForm.data.forms.dispensariesConnection;
      }
    };

    updateForms();
  }, [form]);

  if (
    form &&
    form.embeddable_formConnection &&
    form.embeddable_formConnection.edges &&
    form.embeddable_formConnection.edges.length
  ) {
    marketingIntakeForm = {
      formConnection: { ...form.embeddable_formConnection }
    };

    options = {
      type: form.embeddable_formConnection.edges[0].node.type,
      config: formConfig,
      submitForm: async (data) => {
        try {
          await axios.post(
            '/api/form-submit',
            marketingIntakeMap(data as MarketingFormDataType)
          );
        } catch (err) {}
        setPromptedForMailing('1');
      },
      confirmationCallback: () => toggle(),
      closeButton: {
        text: 'No Thanks',
        variant: 'secondary',
        callBackFunc: () => setMaybeLater()
      }
    };
  } else {
    return null;
  }

  return (
    <Dialog
      blockScroll={true}
      className="mailing-opt-in-dialog padding-16 bg-white"
      closeOnEscape
      dismissableMask={true}
      draggable={false}
      onHide={() => setMaybeLater()}
      resizable={false}
      visible={open}>
      <MarketingIntakeForm
        {...(embeddableFormMap(
          { ...marketingIntakeForm },
          options
        ) as MarketingIntakeProps)}
      />
    </Dialog>
  );
};
